<template>
  <div class="preloader__wrapper">
    <div class="preloader" />
  </div>
</template>

<style scoped lang="css">
.preloader__wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.preloader{
  display: block;
  position: relative;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid #C3A6D9;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  opacity: .7;
}

.preloader::before,
.preloader::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
}
.preloader::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: #BC9AD6;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3.5s linear infinite;
  opacity: .4;
}
.preloader::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: #8142B2;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.75s linear infinite;
  opacity: .6;
}
-webkit-@keyframes spin {
          -webkit-from {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          -webkit-to {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
