<template>
  <button
    :class="{ loading: loader, 'v-btn_trs': isTranparent, 'v-btn_big': isBig }"
    class="v-btn"
  >
    <span
      v-show="loader"
      ref="loader"
      class="v-btn__loader"
    />
    <slot v-if="!loader" />
  </button>
</template>

<script>
import { gsap } from 'gsap'
export default {
  name: 'VBtn',
  props: {
    loader: {
      type: Boolean,
      default: false
    },
    isTranparent: {
      type: Boolean,
      default: false
    },
    isBig: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    loader: {
      handler (newValue) {
        if (newValue) {
          this.animateLoader()
        } else {
          this.stopLoaderAnimation()
        }
      }
    }
  },
  mounted () {},
  methods: {
    animateLoader () {
      const loader = this.$refs.loader

      if (loader) {
        gsap.to(loader, {
          rotation: '+=360',
          repeat: -1,
          duration: 1,
          ease: 'linear'
        })
      }
    },
    stopLoaderAnimation () {
      const loader = this.$refs.loader

      if (loader) {
        gsap.killTweensOf(loader)
      }
    }
  }
}
</script>

<style lang="scss">
.v-btn {
  @include btn;
  min-height: 40px;

  /* .v-btn_big */
  &_big {
    @include btn-big;
  }

  /* .v-btn_trs */
  &_trs {
    @include btn-trs;
  }
}

.v-btn.loading {
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.v-btn__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  transform-origin: center;
  height: 24px;
  border: 2px solid $white;
  border-top-color: $dark-blue;
  border-radius: 50%;
  pointer-events: none;
}
</style>
