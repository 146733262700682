<template>
  <div class="header-messenger__list">
    <a
      href="#"
      class="header-messenger mr-lg-2"
    >
      <svg
        width="32"
        height="32"
      >
        <use xlink:href="#whatsapp" />
      </svg>
    </a>

    <a
      href="#"
      class="header-messenger"
    >
      <svg
        width="32"
        height="32"
      >
        <use xlink:href="#telegram" />
      </svg>
    </a>
  </div>
</template>
<script setup>
</script>
