import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import { vMaska } from 'maska/vue'
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import axios from 'axios'

import i18n from '@/utils/i18n'
import { getCookie } from '@/utils/cookies'
window.Pusher = Pusher

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  encrypted: true,
  authEndpoint: `${process.env.VUE_APP_API_URL}api/broadcasting/auth`,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}api/broadcasting/auth`,
            {
              socket_id: socketId,
              channel_name: channel.name
            },
            { headers: { Authorization: `Bearer ${store.getters.TOKEN}` } }
          )
          .then((response) => {
            callback(false, response.data)
          })
          .catch((error) => {
            callback(true, error)
          })
      }
    }
  }
})

const token = getCookie('token')
const userId = getCookie('user_id')

token ? localStorage.setItem('token', token) : localStorage.removeItem('token')
userId ? localStorage.setItem('user_id', userId) : localStorage.removeItem('user_id')

const app = createApp(App).directive('maska', vMaska)

app.use(i18n)
app.use(store).use(router).mount('#app')
