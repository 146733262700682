export const dataProvider = {
  /**
   * @param {string} uri
   *
   */
  getPageContent: async (uri) => {
    try {
      const url = new URL(uri, 'https://example.com')
      let path = url.pathname

      if (path[path.length - 1] === '/') {
        path = path.slice(0, -1)
      }
      const res = await fetch('/dynamic' + (path || uri) + '.json')
      if(!res.ok){
        return this.get404() //todo: а если интернет сдулся? не то пальто...
      }

      return await res.json()
    }
    catch (e){
      return this.get404()
    }
  },
  getShortLink: async (uri) => {
    const url = new URL(uri, 'https://example.com')
    let path = url.pathname

    if (path[path.length - 1] === '/') {
      path = path.slice(0, -1)
    }

    const res = await fetch(`${process.env.VUE_APP_API_URL}api/short_links` + path)
    if (!res.ok){
      return false
    }
    const json = await res.json()
    if (json.code !== 200) {
      return false
    }
    window.location.href = await json.data.link
  },
  get404: async () => {
    await this.getShortLink(route.fullPath)

    return {
      componentName: '404',
    }
  },
  getTopMenu: async () => {
    try {
      const res = await fetch('/layout/topMenu.json')

      if(!res.ok){
        return [] //todo: а если интернет сдулся? не то пальто...
      }

      return await res.json()
    }
    catch (e){
      return []
    }
  },
  getNews: async () => {
    try {
      const res = await fetch('/layout/news.json')

      if(!res.ok){
        return [] //todo: а если интернет сдулся? не то пальто...
      }

      return await res.json()
    }
    catch (e){
      return []
    }
  },
  getFaq: async () => {
    try {
      const res = await fetch('/layout/faq.json')

      if(!res.ok){
        return [] //todo: а если интернет сдулся? не то пальто...
      }

      return await res.json()
    }
    catch (e){
      return []
    }
  }

}
