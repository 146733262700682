<template>
  <v-header />
  <div class="dashboard-layout dashboard">
    <div class="dashboard__container">
      <div
        v-if="ADMIN"
        class="dashboard__admin-panel"
      >
        Admin mode
      </div>
      <div class="dashboard__links">
        <router-link
          class="dashboard__link"
          :to="{
            path: '/loan',
            query: {
              user_id: $route.query.user_id,
              from_crm: $route?.query?.from_crm || null,
            },
          }"
        >
          Текущий заём
        </router-link>
        <router-link
          class="dashboard__link"
          :to="{
            path: '/history',
            query: {
              user_id: $route.query.user_id,
              from_crm: $route?.query?.from_crm || null,
            },
          }"
        >
          История займов
        </router-link>
        <router-link
          class="dashboard__link"
          :to="{
            path: '/documents',
            query: {
              user_id: $route.query.user_id,
              from_crm: $route.query.from_crm,
            },
          }"
        >
          Документы
        </router-link>
        <router-link
          class="dashboard__link"
          :to="{
            path: '/files',
            query: {
              user_id: $route.query.user_id,
              from_crm: $route.query.from_crm,
            },
          }"
        >
          Загруженные файлы
        </router-link>
      </div>
      <router-view />
    </div>
  </div>
  <v-footer />
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import VHeader from '@/components/UI/v-header.vue'
import VFooter from '@/components/UI/v-footer.vue'

export default {
  name: 'DashboardLayout',
  components: { VFooter, VHeader },
  computed: {
    ...mapGetters(['USER', 'ADMIN'])
  },
  async created () {
    if (this.$route.query?.from_crm == 'true') {
      this.$store.commit('SET_ADMIN', true)
      if (this.$route?.query?.token?.length > 5) {
        this.$store.commit('SET_TOKEN', this.$route?.query?.token)
      }
    }
    if (this.$route.query?.from_sms == 'true') {
      if (this.$route?.query?.token?.length > 2) {
        await this.GET_CLIENT_FROM_SMS_LINK({
          client: this.$route?.query?.client_id,
          code: this.$route?.query?.token
        })
      }
    }
  },
  async mounted () {
    await this.GET_USER_INFO({
      client: this.$route.query.user_id,
      url: '/auth'
    }).then((res) => {
      if (res?.status === 401) {
        this.$router.push('/')
        this.addNotification({
          message: 'Токен авторизации истёк'
        })
      }
    })
    // await this.GET_ACTIVE_ORDERS(this.$route.query.user_id);
    await this.GET_DOCUMENTS(this.$route.query.user_id)
    await this.GET_HISTORY(this.$route.query.user_id)
    // await this.CREATE_DOCUMENTS(this.$route.query.user_id);
    // this.GET_MORATORIUM_STATUS({
    //   client: this.$route.query.user_id,
    //   url: "/auth",
    // });
    this.$store.state.dashboard.photos = []
    this.USER?.files?.map(async (item) => await this.GET_PHOTOS(item))
  },
  methods: {
    ...mapActions([
      'GET_USER_INFO',
      'GET_ACTIVE_ORDERS',
      'GET_DOCUMENTS',
      'GET_HISTORY',
      'GET_PHOTOS',
      'addNotification',
      'CREATE_DOCUMENTS',
      'GET_CLIENT_FROM_SMS_LINK'
    ])
  },
}
</script>
<style lang="scss" scoped>
.dashboard {
  /* .dashboard__container */
  background: $white-pink;

  &__container {
    max-width: 1160px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 10px;
  }
  /* .dashboard__links */

  &__links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  /* .dashboard__link */

  &__link {
    padding: 10px;
    color: $accent;
    border: 1px solid $accent;
    transition: 0.5s;
    font-size: 14px;
    border-radius: 5px;
    margin-right: 20px;
    font-weight: 500;
    margin-bottom: 12px;
    &:last-child {
      margin-right: 0;
    }
    &.router-link-exact-active {
      background-color: $accent;
      color: $white;
    }
  }

  /* .dashboard__banner */

  &__banner {
    margin-top: 25px;
    max-width: 500px;
    background: linear-gradient(
      94.8deg,
      #95e0dd 0%,
      #55c998 34.9%,
      #3fe4c6 100%
    );
    border-radius: 15px;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-height: 100px;
  }

  /* .dashboard__banner-title */

  &__banner-title {
    font-weight: 500;
    font-size: 24px;
    color: $white;
    margin: 0;
  }

  /* .dashboard__banner-icon */

  &__banner-icon {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
  }

  /* .dashboard__admin-panel */

  &__admin-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 80px;
    font-size: 24px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgb(142, 201, 55);
    z-index: 9999999999;
  }
}

@media (max-width: 575px) {
  .dashboard-layout {
  }
  .dashboard {
    /* .dashboard__container */

    &__container {
    }

    /* .dashboard__links */

    &__links {
    }

    /* .dashboard__link */

    &__link {
    }

    /* .dashboard__banner */

    &__banner {
    }

    /* .dashboard__banner-title */

    &__banner-title {
      font-size: 20px;
      max-width: 50%;
    }

    /* .dashboard__banner-icon */

    &__banner-icon {
    }
  }
}
</style>
