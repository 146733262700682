<template>
  <transition
    name="fade"
    :css="true"
  >
    <div
      v-show="visible"
      class="popup"
      :class="{ opened: visible }"
    >
      <div class="popup__content">
        <div class="popup__head">
          <h5 class="popup__title">
            <slot name="title" />
          </h5>
          <button
            style="position: absolute; right: 22px;"
            @click="closePopup"
          >
            <svg
              width="40"
              height="40"
              fill="grey"
            >
              <use xlink:href="#cross-1" />
            </svg>
          </button>
        </div>
        <div class="popup__body">
          <slot name="body" />
        </div>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import VIcon from '@/components/UI/v-icon.vue'
import VBtn from '@/components/UI/v-btn.vue'

export default {
  name: 'VPopup',
  components: { VBtn, VIcon },
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    confirmed: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    closePopup() {
      this.$emit('closePopup')
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999999;
  width: 100vw;
  height: 100vh;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    z-index: -1;
  }

  /* .popup__content */
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 50px;
    background-color: $white;
    max-width: 1200px;
    padding: 40px 60px 50px 60px;
    border-radius: 20px;
    max-height: 75vh;
    overflow-y: auto;
  }

  /* .popup__head */
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  /* .popup__title */
  &__title {
    color: $accent;
    font-family: Sansation;
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;
    margin: 0;
    text-transform: uppercase;
  }

  & :deep(ol) {
    padding: 0;
    margin: 0;
    padding-left: 1.3em;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 130%;
    li {

    }
    a {
      font-weight: 500;
      text-decoration-line: underline;
    }
  }
}
@media (max-width: 1200px) {
  .popup {
    &__content {
      padding: 20px;
      max-width: 90vw;
    }

    & :deep(ol) {
      font-size: 16px;
    }
  }
}
@media (max-width: 575px) {
  .popup {
    &__content {
      gap: 30px;
    }
    &__head {
      flex-direction: column;
      align-items: flex-start;
    }
    &__close {
      align-self: flex-end;
      order: -1;
    }
  }
}
</style>
