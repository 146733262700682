<template>
  <component
    :is="comp"
    v-if="comp"
    v-bind="props.data"
    @ready="onReady"
    @close="onClose"
  />
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'

const onReady = (data) => {
  emits('ready', data)
}

const onClose = (data) => {
  emits('close', data)
}

// eslint-disable-next-line no-undef
const props = defineProps({
  component: {
    type: String,
    default: '',
  },
  data: {},
})

// eslint-disable-next-line no-undef
const emits = defineEmits(['ready', 'close'])

const comp = computed(() => {
  try {
    return defineAsyncComponent(() => import(`./../common/popups/${props.component}.vue`).catch((e) => {
      // eslint-disable-next-line no-console
      console.warn(`[AsyncComponent] Component "${props.component}" wasn't found in Sections path`)

      return undefined
    }))
  }
  catch (e){
    console.warn(`[AsyncComponent] Component "${props.component}" wasn't found in Sections path`)

    return undefined
  }
})

</script>
