<template>
  <footer class="v-footer">
    <div class="v-footer__container">
      <div class="v-footer__container-left">
        <v-icon
          class="v-footer__logo"
          :icon="require(`@/assets/svg/logo.svg`)"
        />
        <div class="v-footer__license">
          <p>
            Текст о лицензии МФО, номер и юридическая информация о компании,
            необходимая для помещения в подвал сайта
          </p>
        </div>
      </div>
      <div class="v-footer__container-middle">
        <a href="mailto:info@creditboost.com">info@creditboost.com</a>
        <a href="tel:+79123456789">+7 (912) 345-67-89</a>
      </div>
      <div class="v-footer__container-right">
        <v-icon :icon="require('@/assets/svg/mastercard.svg')" />
        <v-icon :icon="require('@/assets/svg/maestro.svg')" />
        <v-icon :icon="require('@/assets/svg/mir.svg')" />
        <v-icon :icon="require('@/assets/svg/visa.svg')" />
      </div>
    </div>
  </footer>
</template>
<script>
import VIcon from '@/components/UI/v-icon.vue'

export default {
  name: 'VFooter',
  components: { VIcon },
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.v-footer {
  border-radius: 90px 90px 0px 0px;
  border-top: 2px solid var(---Dark_Gray, #a3a3a3);
  background-color: $silver;
  font-family: $font-inter;
  /* .v-footer__container */

  &__container {
    max-width: 1356px;
    gap: 20px;
    width: 100%;
    margin: 0 auto;
    padding: 60px 10px 70px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__container-left {
    max-width: 470px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
    span {
      margin-bottom: 20px;
    }
  }

  &__license {
    font-family: $font-inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    color: $grayish;
    letter-spacing: 0;
  }

  &__container-middle {
    display: flex;
    flex-direction: column;
    gap: 20px;
    a {
      font-family: $font-inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.375;
      text-align: left;
      color: $text-dark-green;
    }
  }

  &__container-right {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
@media (max-width: 768px) {
  .v-footer {
    &__container {
      flex-direction: column;
      align-items: center;
    }
    &__container-left {
      gap: 20px;
      align-items: center;
      text-align: center;
      max-width: 320px;
    }
    &__container-middle,
    &__container-left,
    &__container-right {
      width: 100%;
    }
    &__container-middle,
    &__container-left {
      align-items: center;
    }
  }

}
@media (max-width: 619px) {
  .v-footer {
    /* .v-footer__docs-column */

  }
}
@media (max-width: 575px) {
  .v-footer {
    /* .v-footer__container */

    &__container {
    }

    /* .v-footer__logo */

    &__logo {
    }

    /* .v-footer__payment-icon */

    &__payment-icon {
    }

    /* .v-footer__contacts */

    &__contacts {
      flex-direction: column;
    }

    /* .v-footer__contanct */

    &__contanct {
    }

    /* .v-footer__license */

    &__license {
    }
  }
}
.feedback__dialog-descr {
  font-size: 13px;
  margin-bottom: 12px;
}
.feedback__dialog-btn {
}
.feedback__dialog-inp {
  margin-bottom: 12px;
}
</style>
