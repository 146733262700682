<template>
  <component
    :is="layout"
    v-if="startApp"
  >
    <router-view />
  </component>
  <v-notification />
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

import DefaultLayout from '@/layouts/DefaultLayout'
import DashboardLayout from '@/layouts/DashboardLayout'
import VNotification from '@/components/UI/v-notification.vue'
import { useI18n } from 'vue-i18n'
import { getCookie, setCookie } from '@/utils/cookies'

if (CSS && 'paintWorklet' in CSS) CSS.paintWorklet.addModule('/assets/libs/smooth-corners.js')
export default {
  components: {
    DefaultLayout,
    DashboardLayout,
    VNotification
  },
  provide: {
    $: window.$,
  },
  data() {
    return {
      startApp: false,
    }
  },
  computed: {
    ...mapGetters(['USER', 'TOKEN']),
    layout() {
      switch (this.$route.meta?.layout) {
      case 'cabinet':
        return defineAsyncComponent(() => import('@/layouts/CabinetLayout.vue'))
      case 'profile':
        return defineAsyncComponent(() => import('@/layouts/ProfileLayout.vue'))
      case 'auth':
        return defineAsyncComponent(() => import('@/layouts/AuthLayout.vue'))
      case 'dynamic':
        return defineAsyncComponent(() => import('@/layouts/DynamicLayout.vue'))
      default:
        return defineAsyncComponent(() => import('@/layouts/DefaultLayout.vue'))
      }
    }
  },
  beforeMount() {
    const token = getCookie('token')
    const userId = getCookie('user_id')

    if (userId) {
      this.$store.commit('SET_CLIENT_ID', userId)
    }
    if (token) {
      this.$store?.commit('SET_TOKEN', token)
    }

    const { t } = useI18n({ useScope: 'global' })

    this.$options.provide = {
      't': t
    }

    this.syncApp()
  },
  mounted() {
    // Сохраняем в куки
    const mySearchParams = new URLSearchParams(window.location.search)

    for (const [key, value] of mySearchParams.entries()) {
      setCookie(key, value, 30)
    }
  },
  methods: {
    ...mapActions(['GET_MORATORIUM_STATUS', 'addNotification', 'GET_USER_INFO', 'GET_DEFAULT_TARIFF_FOR_CREDIT_CALCULATOR', 'GET_CONFIGURATIONS']),
    async syncApp() {
      const promises = [await this.GET_DEFAULT_TARIFF_FOR_CREDIT_CALCULATOR(), await this.GET_CONFIGURATIONS()]

      if (this.TOKEN) {
        promises.push(await this.GET_USER_INFO())
      }

      await Promise.all(promises).then(() => this.startApp = true)
    },
  },
}
</script>

<style lang="scss">
@import "assets/scss/app.scss";
</style>
