const $ = window.$

export default function () {
  if ($(window).width() < 1300) {
    $('.header-nav').appendTo('.header-menu__nav')
    // $('.header-messenger__list').appendTo('.header-menu__messengers');

    $('.profile-header__tel').appendTo('.profile-header__menu-in')
  } else {
    $('.header-nav').appendTo('.header-nav__wrapper')
    // $('.header-messenger__list').appendTo('.header-messenger__wrapper');

    $('.profile-header__tel').appendTo('.profile-header__tel-wrapper')
  }
}
