import { createStore } from 'vuex'
import apiRequest from '../middlewares/api-request'
import signup from './modules/signup'
import dashboard from './modules/dashboard'
import notifications from './modules/notifications'
import axios from 'axios'
import DeviceDetector from 'device-detector-js'

export default createStore({
  state: {
    test: 24,
    moratorium: {
      status: false,
      end_date: null
    },
    pageTitle: undefined,
    rightMenu: undefined,
    rightBanners: undefined,
    breadcrumbs: undefined,
    pageParams: undefined,
    userCity: undefined,
    rightItems: undefined,
    tariffs: [],
    pageLoading: true,
    configurations: [],
  },
  getters: {
    REGISTRATION_ID (state) {
      return state.test
    },
    MORATORIUM_STATUS (state) {
      return state.moratorium
    },
    USER_CITY (state) {
      return state.userCity
    },
    IS_MOBILE () {
      const detector = new DeviceDetector()
      const device = detector.parse(navigator.userAgent)

      return ['iOS', 'Android'].includes(device.os.name) || window.screen.width <= 550
    },
    BROWSER_INFO () {
      const detector = new DeviceDetector()

      return detector.parse(navigator.userAgent)
    },
    IS_APPLE () {
      const detector = new DeviceDetector()
      const device = detector.parse(navigator.userAgent)

      return ['Mac', 'iOS'].includes(device.os.name)
    },
    TARIFFS (state) {
      return state.tariffs
    },
    PAGE_LOADING (state) {
      return state.pageLoading
    },
    REGISTER_CONFIGURATION (state) {
      return state.configurations.filter(cfg => cfg.type === 'registration')[0]
    },
    IDENTITY_CONFIGURATION (state) {
      return state.configurations.filter(cfg => cfg.type === 'identity')
    },
    LOAN_CONFIGURATION (state) {
      return state.configurations.filter(cfg => cfg.type === 'loan')[0]
    }
  },
  mutations: {
    SET_MORATORIUM_STATUS: (state, data) => {
      state.moratorium = {
        status: Boolean(data.status),
        end_date: data.end_date
      }
    },
    SET_TARIFFS: (state, data) => {
      state.tariffs = data.data.tariffs
    },
    SET_PAGE_LOADING: (state, flag) => {
      state.pageLoading = flag
    },
    SET_CONFIGURATIONS: (state, data) => {
      state.configurations = data.data.data
    }
  },
  actions: {
    async executeRecaptcha ({ commit }) {
      try {
        const token = await window.$recaptcha('login')

        throw token
      } catch (error) {
        throw error
      }
    },
    async CREATE_DOCUMENTS ({}, client) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}api/clients/documents/store`,
            null,
            {
              headers: {
                Authorization: 'Bearer ' + this.getters.TOKEN
              }
            }
          )
          .then((res) => {
            if (res?.response?.status === 500) { throw new Error('Ошибка сервера') } else resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async RESET_PASSWORD ({ commit }, { step, payload }) {
      if (step === 0) {
        return new Promise((resolve, reject) => {
          axios
            .post(
              `${process.env.VUE_APP_API_URL}api/clients/password_reset_request`,
              payload
            )
            .then((res) => {
              if (res?.response?.status === 500) { throw new Error('Ошибка сервера') } else resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
      if (step === 1) {
        return new Promise((resolve, reject) => {
          axios
            .patch(
              `${process.env.VUE_APP_API_URL}api/clients/password_reset_confirm/${payload?.user_id}`,
              payload
            )
            .then((res) => {
              if (res?.response?.status === 500) { throw new Error('Ошибка сервера') } else resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
      if (step === 2) {
        return new Promise((resolve, reject) => {
          axios
            .patch(
              `${process.env.VUE_APP_API_URL}api/clients/password_reset/${payload?.user_id}`,
              payload
            )
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
    },
    async TEST ({ commit }, payload) {
      return new Promise((resolve) => {
        const res = axios.get('https://jsonplaceholder.typicode.com/todos/1')

        resolve(res)
      })
    },
    async GET_CLIENT () {
      const res = await apiRequest.function('GET', '/clients', null, null, null, {
        Authorization: `Bearer ${this.getters.TOKEN}`
      })
    },
    async CHECK_SMS ({ commit }, { method, url, code, type, phone }) {
      return await apiRequest.function(
        method,
        `api/clients/${url}`,
        null,
        null,
        { code, type, phone }
      )
    },
    async GET_MORATORIUM_STATUS ({ commit }) {
      const res = await apiRequest.function(
        'GET',
        'api/clients/moratorium',
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )

      commit('SET_MORATORIUM_STATUS', res.data.data.data)

      return res.data.data.data
    },
    async SEND_FEEDBACK ({ commit }, payload) {
      return await apiRequest.function(
        'POST',
        'api/feedback/store',
        null,
        null,
        payload
      )
    },
    async SEND_CONTINUE_CODE ({ commit }, phone) {
      return new Promise((resolve) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}api/clients/phone-confirmation/request`
          )
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            if (error?.response?.status === 422) {
              this.dispatch('addNotification', {
                message: error?.response?.data?.message
              })
            }
            resolve(error?.response)
          })
      })
    },
    async CHECK_CONTINUE_CODE ({ commit }, { client_id, code }) {
      return new Promise((resolve) => {
        axios
          .patch(
            `${process.env.VUE_APP_API_URL}api/clients/${client_id}/phone-confirmation/confirm`,
            {
              code
            }
          )
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            if (error?.response?.status === 422) {
              this.dispatch('addNotification', {
                message: error?.response?.data?.message
              })
            }
            resolve(error?.response)
          })
      })
    },
    async CHECK_TOKEN ({ commit }, token) {
      return new Promise((resolve) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}api/client/check_order/${token}`)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            if (error?.response?.status === 422) {
              this.dispatch('addNotification', {
                message: error?.response?.data?.message
              })
            }
            resolve(error?.response)
          })
      })
    },
    async GET_DEFAULT_TARIFF_FOR_CREDIT_CALCULATOR ({ commit }) {
      return await apiRequest.function(
        'GET',
        'api/tariff/default',
        ['SET_TARIFFS'],
        null,
        null,
      )
    },
    async GET_CONFIGURATIONS () {
      return await apiRequest.function(
        'GET',
        'api/registration/settings',
        ['SET_CONFIGURATIONS'],
        null,
        null,
      )
    }
  },
  modules: { signup, dashboard, notifications }
})
