<template>
  <div
    id="popup-call"
    class="popup__wrapper popup__wrapper--common"
    style="display: none"
  >
    <div class="popup__in">
      <div class="title-h3 font-medium spacing-4 tac lh12">
        Закажите обратный звонок
      </div>

      <div class="text lh14 spacing-3 tac">
        Оставьте имя и телефон, и мы перезвоним вам в рабочее время: с 9:00 до 20:30 по Москве.
      </div>

      <form class="popup-call__in spacing-4">
        <div class="form-field__wrapper spacing-4">
          <div class="form-field form-field--error">
            <input
              id="popup-name"
              type="text"
              class="form-field__input"
              placeholder=" "
              value="Wrong name"
            >
            <label
              for="popup-name"
              class="form-field__label"
            >Имя</label>
          </div>
          <span class="form-field__error">Сообщение об ошибке</span>
        </div>
        <div class="form-field__wrapper spacing-3">
          <div class="form-field">
            <input
              id="popup-tel"
              type="text"
              class="form-field__input js-tel"
              placeholder=" "
            >
            <label
              for="popup-tel"
              class="form-field__label"
            >Телефон</label>
          </div>
        </div>

        <button
          class="button button-primary w-100"
          data-fancybox
          data-src="#popup-thanks"
        >
          Заказать обратный звонок
        </button>
      </form>
      <div class="form-policy color-dark-800 text4 tac">
        Нажимая на кнопку, я даю согласие на обработку персональных
        <br class="d-none d-md-inline"> данных на основании <router-link
          to="/privacy"
          class="link-underline"
        >
          политики конфиденциальности
        </router-link>
      </div>
    </div>
  </div>
  <div
    id="popup-calc"
    class="popup__wrapper popup__wrapper--common"
    style="display: none"
  >
    <div class="popup__in">
      <div class="title-h3 font-medium spacing-4 tac lh12">
        Рассчитайте сумму займа по Вашему авто
      </div>

      <div class="text lh14 spacing-3 tac">
        Оставьте имя и телефон, и мы перезвоним вам в рабочее время: с 9:00 до 20:30 по Москве.
      </div>

      <form class="popup-call__in spacing-4">
        <div class="form-field__wrapper spacing-4">
          <div class="form-field form-field--error">
            <input
              id="popup-calc-number"
              type="text"
              class="form-field__input"
              placeholder=" "
              value="00 00 00 0"
            >
            <label
              for="popup-calc-number"
              class="form-field__label"
            >Госномер</label>
          </div>
          <span class="form-field__error">Введите корректный госномер</span>
        </div>
        <div class="form-field__wrapper spacing-3">
          <div class="form-field">
            <input
              id="popup-calc-tel"
              type="text"
              class="form-field__input js-tel"
              placeholder=" "
            >
            <label
              for="popup-calc-tel"
              class="form-field__label"
            >Телефон</label>
          </div>
        </div>

        <button
          class="button button-primary w-100"
          data-fancybox
          data-src="#popup-thanks"
        >
          Получить расчет
        </button>
      </form>
      <div class="form-policy color-dark-800 text4 tac">
        Нажимая на кнопку, я даю согласие на обработку персональных
        <br class="d-none d-md-inline"> данных на основании <router-link
          to="/privacy"
          class="link-underline"
        >
          политики конфиденциальности
        </router-link>
      </div>
    </div>
  </div>
  <div
    id="popup-cons"
    class="popup__wrapper popup__wrapper--common"
    style="display: none"
  >
    <div class="popup__in">
      <div class="title-h3 font-medium spacing-4 tac lh12">
        Получите консультацию
      </div>

      <div class="text lh14 spacing-3 tac">
        Оставьте имя и телефон, и мы перезвоним вам в рабочее время: с 9:00 до 20:30 по Москве.
      </div>

      <form class="popup-call__in spacing-4">
        <div class="form-field__wrapper spacing-4">
          <div class="form-field">
            <input
              id="popup-cons-name"
              type="text"
              class="form-field__input"
              placeholder=" "
            >
            <label
              for="popup-cons-name"
              class="form-field__label"
            >Имя</label>
          </div>
        </div>
        <div class="form-field__wrapper spacing-3">
          <div class="form-field">
            <input
              id="popup-cons-tel"
              type="text"
              class="form-field__input js-tel"
              placeholder=" "
            >
            <label
              for="popup-cons-tel"
              class="form-field__label"
            >Телефон</label>
          </div>
        </div>

        <button
          class="button button-primary w-100"
          data-fancybox
          data-src="#popup-thanks"
        >
          Получить расчет
        </button>
      </form>
      <div class="form-policy color-dark-800 text4 tac">
        Нажимая на кнопку, я даю согласие на обработку персональных
        <br class="d-none d-md-inline"> данных на основании <router-link
          to="/privacy"
          class="link-underline"
        >
          политики конфиденциальности
        </router-link>
      </div>
    </div>
  </div>
  <div
    id="popup-thanks"
    class="popup__wrapper popup__wrapper--lg popup__wrapper--thanks popup__wrapper--common"
    style="display: none"
  >
    <div class="popup__in">
      <div class="title-h3 font-medium lh12 spacing-4 tac">
        Спасибо! Ваша заявка принята.
      </div>

      <div class="text lh14 spacing-4 tac">
        Мы свяжемся с Вами в ближайшее время.
      </div>

      <div class="d-flex justify-content-center">
        <span
          class="button button-secondary js-close-fancybox"
        >Ок</span>
      </div>
    </div>
  </div>

  <div
    id="popup-card"
    class="popup__wrapper popup__wrapper--common popup__wrapper--xs"
    style="display: none"
  >
    <div class="popup__in popup__in--align-start">
      <div class="title-h6 font-medium mb-2 lh12">
        Добавление карты
      </div>

      <div class="text4 lh12 spacing-3">
        Для привязки карты мы спишем 1 руб.<br>
        и сразу же вернём обратно.
      </div>

      <form class="popup-call__in">
        <div class="popup-card__fields spacing-35">
          <div class="popup-card__spinner-wrapper">
            <svg
              class="popup-card__spinner"
              width="56"
              height="56"
            >
              <use xlink:href="#spinner" />
            </svg>
            <span>Проверяем Вашу карту...</span>
          </div>
          <div class="form-field__wrapper spacing-4">
            <div class="form-field form-field--small form-field--bg">
              <input
                id="popup-card-number"
                type="text"
                class="form-field__input js-mask-card"
                placeholder=" "
              >
              <label
                for="popup-card-number"
                class="form-field__label"
              >Номер карты</label>
            </div>
          </div>
          <div class="popup-card__fields-row spacing-4">
            <div class="form-field__wrapper">
              <div class="form-field form-field--small form-field--bg">
                <input
                  id="popup-card-date"
                  type="text"
                  class="form-field__input js-mask-year"
                  placeholder=" "
                >
                <label
                  for="popup-card-date"
                  class="form-field__label"
                >MM / YY</label>
              </div>
            </div>
            <div class="form-field__wrapper">
              <div class="form-field form-field--small form-field--bg">
                <input
                  id="popup-card-cvv"
                  type="text"
                  class="form-field__input js-mask-cvv"
                  placeholder=" "
                >
                <label
                  for="popup-card-cvv"
                  class="form-field__label"
                >CVV</label>
              </div>
            </div>
          </div>
          <div class="form-field__wrapper">
            <div class="form-field form-field--small form-field--bg">
              <input
                id="popup-card-email"
                type="email"
                class="form-field__input"
                placeholder=" "
              >
              <label
                for="popup-card-email"
                class="form-field__label"
              >Почта</label>
            </div>
          </div>
        </div>

        <button class="button button-primary button--xs button-icon w-100 radius8 gap-8 spacing-4 js-validate-card">
          <svg
            width="24"
            height="24"
          >
            <use xlink:href="#card" />
          </svg>
          <span class="size14">Добавить карту</span>
        </button>

        <div class="tac text4 color-dark-700 lh12">
          Нажимая «Добавить карту», вы соглашаетесь<br>
          <a
            href="#"
            class="link-underline"
          >с условиями оферты</a>
        </div>
      </form>
    </div>
  </div>

  <div
    id="popup-request"
    class="popup__wrapper popup__wrapper--common"
    style="display: none"
  >
    <div class="popup__in">
      <div class="title-h3 font-medium spacing-4 tac lh12">
        Подать заявку
      </div>

      <div class="text lh14 spacing-3 tac">
        Зарегистрируйтесь в личном кабинете, чтобы подать заявку на займ без посещения офиса
      </div>

      <form class="popup-call__in">
        <div class="form-field__wrapper spacing-4">
          <div class="form-field form-field--phone">
            <input
              id="popup-request-tel"
              type="text"
              class="form-field__input form-field__phone js-tel"
              placeholder="+7"
            >
            <svg class="form-field--phone__flag"><use xlink:href="#rus" /></svg>
          </div>
        </div>

        <div class="checkbox-group spacing-35">
          <div class="checkbox-group__top">
            <label class="checkbox">
              <input
                type="checkbox"
                class="js-set-checked-checkbox-group"
              >
              <span class="checkbox__text">
                <svg><use xlink:href="#check" /></svg>
                <span class="lh14">
                  Я соглашаюсь со всеми<br class="d-none d-lg-inline"> нижеуказанными пунктами
                </span>
              </span>
            </label>

            <button class="button button-fourthary button--square checkbox-group__button js-toggle-checkbox-group">
              <svg
                width="24"
                height="24"
              ><use xlink:href="#chevron-down" /></svg>
            </button>
          </div>
          <div class="checkbox-group__content">
            <div class="checkbox-group__content-list js-checkbox-group-list">
              <label class="checkbox text5">
                <input type="checkbox">
                <span class="checkbox__text">
                  <svg><use xlink:href="#check" /></svg>
                  <span class="lh14">Я принимаю условия <a
                    href="#"
                    class="link-underline"
                  >Соглашения об использовании аналога собственноручной подписи</a> (далее - АСП) а также поручаю ООО МКК «К2КАПИТАЛ» организовать подписание документов посредством АСП</span>
                </span>
              </label>
              <label class="checkbox text5">
                <input type="checkbox">
                <span class="checkbox__text">
                  <svg><use xlink:href="#check" /></svg>
                  <span class="lh14">Я даю <a
                    href="#"
                    class="link-underline"
                  >согласие</a> на обработку и хранение моих персональных данных, в том числе автоматизированную обработку персональных данных</span>
                </span>
              </label>
              <label class="checkbox text5">
                <input type="checkbox">
                <span class="checkbox__text">
                  <svg><use xlink:href="#check" /></svg>
                  <span class="lh14">Я даю <a
                    href="#"
                    class="link-underline"
                  >согласие</a> на получение рекламы</span>
                </span>
              </label>
              <label class="checkbox text5">
                <input type="checkbox">
                <span class="checkbox__text">
                  <svg><use xlink:href="#check" /></svg>
                  <span class="lh14">Я подтверждаю, что в отношениях с ООО МКК «К2КАПИТАЛ» действую сознательно ,
                    своей волей  интересах, мои действия  иными третьими лицами, самостоятельно представляю свои интересы,
                    также подтверждаю что являюсь
                    <a
                      href="#"
                      class="link-underline"
                    >
                      публичным должностным лицом или лицом, связанным с ним
                    </a>
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>

        <button
          class="button button-primary spacing-4 w-100"
          data-fancybox
          data-src="#popup-thanks"
        >
          Выслать SMS
        </button>
        <div class="tac">
          У вас уже есть личный кабинет?<br class="d-lg-none">
          <span class="color-purple">
            <a
              href="signin.html"
              class="link-underline font-medium"
            >Войти</a>
          </span>
        </div>
      </form>
    </div>
  </div>

  <div
    id="popup-mobile"
    class="popup__wrapper popup__wrapper--mobile popup__wrapper--common popup__wrapper--xsm"
    style="display: none"
  >
    <div class="popup__in">
      <div class="popup-mobile__in">
        <div class="title-h45 font-medium spacing-4 tac lh12">
          Продолжим в мобильной версии?
        </div>

        <div class="text4 lh14 spacing-3 tac">
          Для повышения шансов одобрения заявки требуется предоставить фотографии автомобиля с разных ракурсов, что удобно выполнить через мобильное устройство.
        </div>

        <div class="popup-mobile__buttons">
          <button
            class="button button-primary size16 radius8 w-100"
            data-fancybox
            data-src="#popup-thanks"
          >
            Выслать СМС с ссылкой для входа
          </button>
          <button
            class="button button-link size16 w-100 js-close-fancybox"
            data-fancybox
            data-src="#popup-thanks"
          >
            Продолжить с компьютера
          </button>
        </div>

        <img
          class="popup-mobile__picture"
          src="@/assets/img/mobile-dialog-picture.jpg"
          alt="Продолжим в мобильной версии?"
        >
      </div>
    </div>
  </div>

  <LocationPopup />
  <CookiesPopup />

  <div
    id="popup-photo-1"
    class="popup__wrapper popup__wrapper--photo"
    style="display: none"
  >
    <div class="popup-photo__grid">
      <div class="popup-photo__info">
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <div class="title-h6 font-medium spacing-4 lh12 ls08">
              Фото документов
            </div>

            <div class="popup-photo__steps">
              <div class="popup-photo__step">
                <span />
                <div class="font-medium text4 mb-1">
                  Разрешите доступ к камере
                </div>
                <div class="text5 color-dark-700 lh12 mb-2">
                  Перед началом процесса Вам необходимо выдать разрешение на использование Вашей веб-камеры.
                </div>
                <button class="button button-secondary button--sm radius8 size14 js-photo-step-2">
                  Разрешить
                </button>
              </div>
              <div class="popup-photo__step">
                <span />
                <div class="font-medium text4 mb-1">
                  Подготовьте документы
                </div>
                <div class="text5 color-dark-700 lh12">
                  На данном шаге Вам понадобится только паспорт гражданина РФ.
                </div>
              </div>
              <div class="popup-photo__step">
                <span />
                <div class="font-medium text4 mb-1">
                  Сделайте фотографии
                </div>
                <div class="text5 color-dark-700 lh12">
                  На каждом этапе мы сообщим (и покажем), какие фотографии нужно сделать
                </div>
              </div>
            </div>
          </div>
          <button
            class="button button-primary button--sm radius8 size16"
            disabled
          >
            Продолжить
          </button>
        </div>
      </div>
      <div class="popup-photo__main">
        <div class="popup-photo__dummy">
          <svg
            class="popup-photo__spinner"
            width="56"
            height="56"
          >
            <use xlink:href="#spinner" />
          </svg>
          <span>Ожидаем доступа к камере<br> и местоположению...</span>
        </div>
        <div class="popup-photo__close-wrapper">
          <div class="popup-photo__close-in">
            <button class="popup-photo__close js-close-fancybox">
              <svg
                width="40"
                height="40"
              >
                <use xlink:href="#thumb-delete" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="popup-photo-2"
    class="popup__wrapper popup__wrapper--photo"
    style="display: none"
  >
    <div class="popup-photo__grid">
      <div class="popup-photo__info">
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <div class="title-h6 font-medium spacing-4 lh12 ls08">
              Фото документов
            </div>

            <div class="popup-photo__steps">
              <div class="popup-photo__step">
                <span />
                <div class="font-medium text4 mb-1">
                  Разрешите доступ к камере
                </div>
                <div class="text5 color-dark-700 lh12 mb-2">
                  Перед началом процесса Вам необходимо выдать разрешение на использование Вашей веб-камеры.
                </div>
                <button
                  class="button button-secondary button--sm radius8 size14"
                  disabled
                >
                  Разрешить
                </button>
              </div>
              <div class="popup-photo__step">
                <span />
                <div class="font-medium text4 mb-1">
                  Подготовьте документы
                </div>
                <div class="text5 color-dark-700 lh12">
                  На данном шаге Вам понадобится только паспорт гражданина РФ.
                </div>
              </div>
              <div class="popup-photo__step">
                <span />
                <div class="font-medium text4 mb-1">
                  Сделайте фотографии
                </div>
                <div class="text5 color-dark-700 lh12">
                  На каждом этапе мы сообщим (и покажем), какие фотографии нужно сделать
                </div>
              </div>
            </div>
          </div>
          <button class="button button-primary button--sm radius8 size16 js-photo-step-3">
            Продолжить
          </button>
        </div>
      </div>
      <div class="popup-photo__main">
        <img
          class="popup-photo__picture"
          src="@/assets/img/popup-photo.jpg"
          alt="Фото"
        >
        <div class="popup-photo__close-wrapper">
          <div class="popup-photo__close-in">
            <button class="popup-photo__close js-toggle-popover">
              <svg
                width="40"
                height="40"
              >
                <use xlink:href="#thumb-delete" />
              </svg>
            </button>

            <div class="popup-photo__popover">
              <div class="popup-photo__popover-in">
                <div class="text font-medium mb-1">
                  Прервать заполнение?
                </div>
                <div class="text4 spacing-4 lh12">
                  Все фотографии, которые Вы сделали ранее не будут сохранены.
                </div>
              </div>

              <div class="popup-photo__popover-actions">
                <button class="button button-fourthary button--sm radius8 js-close-photo-modal">
                  Прервать
                </button>
                <button class="button button-primary button--sm radius8 js-close-popover">
                  Продолжить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="popup-photo-3"
    class="popup__wrapper popup__wrapper--photo"
    style="display: none"
  >
    <div class="popup-photo__grid">
      <div class="popup-photo__info">
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <div class="popup-photo__header spacing-35">
              <div class="profile-steps">
                <div class="profile-step profile-step--active" />
                <div class="profile-step" />
                <div class="profile-step" />
              </div>

              <div class="text4">
                Шаг 1 из 3
              </div>
            </div>

            <div class="font-medium title-h6 ls08 mb-1 tac">
              Основной разворот
            </div>
            <div class="text5 color-dark-700 tac">
              Сделайте фото основного разворота.
            </div>

            <div class="popup-photo__preview" />

            <div class="text5 color-dark-700 tac lh12">
              Убедитесь, что фотография не засвечена<br> и все данные легкочитаемы
            </div>
          </div>
          <button
            class="button button-primary button--sm radius8 size16"
            disabled
          >
            Продолжить
          </button>
        </div>
      </div>
      <div class="popup-photo__main">
        <img
          class="popup-photo__picture"
          src="@/assets/img/popup-photo.jpg"
          alt="Фото"
        >
        <div class="popup-photo__close-wrapper">
          <div class="popup-photo__close-in">
            <button class="popup-photo__close js-toggle-popover">
              <svg
                width="40"
                height="40"
              >
                <use xlink:href="#thumb-delete" />
              </svg>
            </button>

            <div class="popup-photo__popover">
              <div class="popup-photo__popover-in">
                <div class="text font-medium mb-1">
                  Прервать заполнение?
                </div>
                <div class="text4 spacing-4 lh12">
                  Все фотографии, которые Вы сделали ранее не будут сохранены.
                </div>
              </div>

              <div class="popup-photo__popover-actions">
                <button class="button button-fourthary button--sm radius8 js-close-photo-modal">
                  Прервать
                </button>
                <button class="button button-primary button--sm radius8 js-close-popover">
                  Продолжить
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="popup-photo__actions">
          <button class="button button-primary button--sm size16 button-icon gap-8 js-photo-step-4">
            <svg
              width="24"
              height="24"
            >
              <use xlink:href="#camera" />
            </svg>
            <span class="text4">Сделать фото</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    id="popup-photo-4"
    class="popup__wrapper popup__wrapper--photo"
    style="display: none"
  >
    <div class="popup-photo__grid">
      <div class="popup-photo__info">
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <div class="popup-photo__header spacing-35">
              <div class="profile-steps">
                <div class="profile-step profile-step--active" />
                <div class="profile-step" />
                <div class="profile-step" />
              </div>

              <div class="text4">
                Шаг 1 из 3
              </div>
            </div>

            <div class="font-medium title-h6 ls08 mb-1 tac">
              Основной разворот
            </div>
            <div class="text5 color-dark-700 tac">
              Сделайте фото основного разворота.
            </div>

            <div class="popup-photo__preview" />

            <div class="text5 color-dark-700 tac lh12">
              Убедитесь, что фотография не засвечена<br> и все данные легкочитаемы
            </div>
          </div>
          <button class="button button-primary button--sm radius8 size16 js-photo-step-5">
            Продолжить
          </button>
        </div>
      </div>
      <div class="popup-photo__main">
        <img
          class="popup-photo__picture"
          src="@/assets/img/popup-photo.jpg"
          alt="Фото"
        >
        <div class="popup-photo__close-wrapper">
          <div class="popup-photo__close-in">
            <button class="popup-photo__close js-toggle-popover">
              <svg
                width="40"
                height="40"
              >
                <use xlink:href="#thumb-delete" />
              </svg>
            </button>

            <div class="popup-photo__popover">
              <div class="popup-photo__popover-in">
                <div class="text font-medium mb-1">
                  Прервать заполнение?
                </div>
                <div class="text4 spacing-4 lh12">
                  Все фотографии, которые Вы сделали ранее не будут сохранены.
                </div>
              </div>

              <div class="popup-photo__popover-actions">
                <button class="button button-fourthary button--sm radius8 js-close-photo-modal">
                  Прервать
                </button>
                <button class="button button-primary button--sm radius8 js-close-popover">
                  Продолжить
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="popup-photo__actions">
          <button class="button button-fourthary button--sm size16 button-icon gap-8 js-photo-step-4">
            <span class="text4">Переснять</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    id="popup-photo-5"
    class="popup__wrapper popup__wrapper--photo"
    style="display: none"
  >
    <div class="popup-photo__grid">
      <div class="popup-photo__info">
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <div class="popup-photo__header spacing-35">
              <div class="profile-steps">
                <div class="profile-step profile-step--passed" />
                <div class="profile-step profile-step--active" />
                <div class="profile-step" />
              </div>

              <div class="text4">
                Шаг 2 из 3
              </div>
            </div>

            <div class="font-medium title-h6 ls08 mb-1 tac">
              Прописка
            </div>
            <div class="text5 color-dark-700 tac">
              Сделайте фото основного разворота.
            </div>

            <div class="popup-photo__preview" />

            <div class="text5 color-dark-700 tac lh12">
              Убедитесь, что фотография не засвечена<br> и все данные легкочитаемы
            </div>
          </div>
          <div class="popup-photo__nav">
            <button class="button button-fourthary button--sm radius8 size16">
              Назад
            </button>
            <button
              class="button button-primary button--sm radius8 size16"
              disabled
            >
              Продолжить
            </button>
          </div>
        </div>
      </div>
      <div class="popup-photo__main">
        <img
          class="popup-photo__picture"
          src="@/assets/img/popup-photo.jpg"
          alt="Фото"
        >
        <div class="popup-photo__close-wrapper">
          <div class="popup-photo__close-in">
            <button class="popup-photo__close js-toggle-popover">
              <svg
                width="40"
                height="40"
              >
                <use xlink:href="#thumb-delete" />
              </svg>
            </button>

            <div class="popup-photo__popover">
              <div class="popup-photo__popover-in">
                <div class="text font-medium mb-1">
                  Прервать заполнение?
                </div>
                <div class="text4 spacing-4 lh12">
                  Все фотографии, которые Вы сделали ранее не будут сохранены.
                </div>
              </div>

              <div class="popup-photo__popover-actions">
                <button class="button button-fourthary button--sm radius8 js-close-photo-modal">
                  Прервать
                </button>
                <button class="button button-primary button--sm radius8 js-close-popover">
                  Продолжить
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="popup-photo__actions">
          <button class="button button-primary button--sm size16 button-icon gap-8 js-photo-step-6">
            <svg
              width="24"
              height="24"
            >
              <use xlink:href="#camera" />
            </svg>
            <span class="text4">Сделать фото</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    id="popup-photo-6"
    class="popup__wrapper popup__wrapper--photo"
    style="display: none"
  >
    <div class="popup-photo__grid">
      <div class="popup-photo__info">
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <div class="popup-photo__header spacing-35">
              <div class="profile-steps">
                <div class="profile-step profile-step--passed" />
                <div class="profile-step profile-step--active" />
                <div class="profile-step" />
              </div>

              <div class="text4">
                Шаг 2 из 3
              </div>
            </div>

            <div class="font-medium title-h6 ls08 mb-1 tac">
              Прописка
            </div>
            <div class="text5 color-dark-700 tac">
              Сделайте фото основного разворота.
            </div>

            <div class="popup-photo__preview" />

            <div class="text5 color-dark-700 tac lh12">
              Убедитесь, что фотография не засвечена<br> и все данные легкочитаемы
            </div>
          </div>
          <div class="popup-photo__nav">
            <button class="button button-fourthary button--sm radius8 size16">
              Назад
            </button>
            <button class="button button-primary button--sm radius8 size16">
              Продолжить
            </button>
          </div>
        </div>
      </div>
      <div class="popup-photo__main">
        <img
          class="popup-photo__picture"
          src="@/assets/img/popup-photo.jpg"
          alt="Фото"
        >
        <div class="popup-photo__close-wrapper">
          <div class="popup-photo__close-in">
            <button class="popup-photo__close js-toggle-popover">
              <svg
                width="40"
                height="40"
              >
                <use xlink:href="#thumb-delete" />
              </svg>
            </button>

            <div class="popup-photo__popover">
              <div class="popup-photo__popover-in">
                <div class="text font-medium mb-1">
                  Прервать заполнение?
                </div>
                <div class="text4 spacing-4 lh12">
                  Все фотографии, которые Вы сделали ранее не будут сохранены.
                </div>
              </div>

              <div class="popup-photo__popover-actions">
                <button class="button button-fourthary button--sm radius8 js-close-photo-modal">
                  Прервать
                </button>
                <button class="button button-primary button--sm radius8 js-close-popover">
                  Продолжить
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="popup-photo__actions">
          <button class="button button-fourthary button--sm size16 button-icon gap-8">
            <span class="text4">Переснять</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--mobile flow-->
  <div
    id="popup-mobile-photo-1"
    class="popup__wrapper popup__wrapper--photo"
    style="display: none"
  >
    <div class="popup-photo__grid">
      <div class="popup-photo__info">
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <div class="title-h6 font-medium spacing-4 lh12 ls08">
              Фото документов
            </div>

            <div class="popup-photo__steps">
              <div class="popup-photo__step">
                <span />
                <div class="font-medium text4 mb-1">
                  Разрешите доступ к камере
                </div>
                <div class="text5 color-dark-700 lh12 mb-2">
                  Перед началом процесса Вам необходимо выдать разрешение на использование Вашей веб-камеры.
                </div>
                <button class="button button-secondary button--sm radius8 size14 js-photo-step-2">
                  Разрешить
                </button>
              </div>
              <div class="popup-photo__step">
                <span />
                <div class="font-medium text4 mb-1">
                  Подготовьте документы
                </div>
                <div class="text5 color-dark-700 lh12">
                  На данном шаге Вам понадобится только паспорт гражданина РФ.
                </div>
              </div>
              <div class="popup-photo__step">
                <span />
                <div class="font-medium text4 mb-1">
                  Сделайте фотографии
                </div>
                <div class="text5 color-dark-700 lh12">
                  На каждом этапе мы сообщим (и покажем), какие фотографии нужно сделать
                </div>
              </div>
            </div>
          </div>
          <button
            class="button button-primary button--sm radius8 size16"
            data-fancybox
            data-src="#popup-mobile-photo-2"
          >
            Продолжить
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    id="popup-mobile-photo-3"
    class="popup__wrapper popup__wrapper--mobile-photo popup__wrapper-prevent-close"
    style="display: none;"
  >
    <div class="mobile-photo__in">
      <div class="fancybox-close-small">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
        </svg>
      </div>
      <div class="mobile-photo__content">
        <div class="mobile-photo__top">
          <div class="font-medium size24 ls08 mb-1">
            Основной разворот
          </div>
          <div class="text5 mb-2">
            Сделайте фото основного разворота.
          </div>

          <div class="popup-photo__header">
            <div class="profile-steps">
              <div class="profile-step profile-step--active" />
              <div class="profile-step" />
              <div class="profile-step" />
            </div>

            <div class="text4">
              Шаг 1 из 3
            </div>
          </div>
        </div>

        <div
          class="mobile-photo-result__picture"
          style="background-image: url('@/assets/img/passport.jpg');"
        />

        <div class="mobile-photo-result__actions">
          <button class="button button-fourthary button--sm radius8 size16">
            Переснять
          </button>
          <button class="button button-primary button--sm radius8 size16">
            Продолжить
          </button>
          <button class="button-link button-sm size16 font-medium">
            К предыдщуему шагу
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    id="popup-mobile-photo-cancel"
    class="popup__wrapper popup__wrapper--cancel"
    style="display: none;"
  >
    <div class="popup-cancel">
      <div class="popup-cancel__in spacing-4">
        <div class="font-medium size24 ls08 mb-1">
          Прервать заполнение?
        </div>
        <div class="text4">
          Все фотографии, которые Вы сделали ранее не будут сохранены.
        </div>
      </div>
      <div class="popup-cancel__actions">
        <button class="button button-fourthary button--sm radius8 size16 js-close-photo-cancel">
          Прервать
        </button>
        <button class="button button-primary button--sm radius8 size16 js-close-fancybox">
          Продолжить
        </button>
      </div>
    </div>
  </div>
  <div
    id="popup-mobile-photo-info"
    class="popup__wrapper popup__wrapper--info"
    style="display: none;"
  >
    <div class="popup-info tac">
      <div class="font-medium size24 ls08 mb-1">
        Основной разворот
      </div>
      <div class="text5 color-dark-700 spacing-4">
        Сделайте фото основного разворота.
      </div>
      <div class="popup-info__sample spacing-4" />
      <div class="text5 lh12 color-dark-700">
        Убедитесь, что фотография не засвечена и все данные легкочитаемы
      </div>
    </div>
  </div>

  <div>
    <AsyncPopupItem
      v-for="(popup, index) in popups"
      :key="'pp-' + index"
      :component="popup.component"
      :data="popup.data || {}"
      @ready="openPopup(popup.component, $event)"
      @close="closePopup(popup.component)"
    />
  </div>
</template>
<script setup>
import CookiesPopup from '@/components1/common/popups/CookiesPopup.vue'
import LocationPopup from '@/components1/common/popups/LocationPopup.vue'
import AsyncPopupItem from '@/components1/utils/AsyncPopupItem.vue'
import { onBeforeUnmount, onMounted, ref } from 'vue'

const popups = ref({})

const $ = window.$

const fancyOpts = {
  animationDuration: 600,
  // animationEffect: 'slide-in-in',
  touch: false,
  scrolling: true,
  clickOutside: false,
  helpers : {
    overlay : { closeClick: false }
  },
}

const openPP = (src, componentName, options = {}) => {
  const ppTarget = $(document).find(src)

  $.fancybox.open({
    src: ppTarget,
    type: 'inline',
    opts: Object.assign({
      afterClose: () => {
        if(popups.value[componentName]){
          delete popups.value[componentName]
        }
      }
    }, fancyOpts, options),
  })
}

const openPopup = (componentName, src) => {
  openPP(src, componentName)
}

const closePopup = (component = undefined) => {
  $.fancybox.close()
  if(component && popups.value[component]){
    delete popups.value[component]
  }
}

const addPopup = (event) => {
  const popupComponent = $(event.target).attr('data-pp')

  let componentData = {}

  Array.from(event.target.attributes).filter(attribute => attribute.name.slice(0, 8) === 'data-pp-').forEach((attribute) => {
    if(attribute && attribute.name && attribute.value){
      const tmpDataKey = attribute.name.slice(8)

      if(tmpDataKey && tmpDataKey.length && tmpDataKey.length > 0){
        componentData[tmpDataKey] = attribute.value
      }
    }
  })

  if(popupComponent && popupComponent.length && popupComponent.length > 0){
    popups.value[popupComponent] = {
      component: popupComponent,
      data: componentData,
    }
  }
}

onMounted(() => {
  $(document).on('click', '[data-pp]', addPopup)
})

onBeforeUnmount(() => {
  $(document).off('click', '[data-pp]', addPopup)
  Object.keys(popups.value).forEach(() => {
    $.fancybox.close()
  })
  popups.value = {}
})

</script>
