<template>
  <label class="checkbox">
    <input
      type="checkbox"
      :value="value"
      :checked="isChecked"
      @change="onChange"
    >
    <span class="checkbox__text">
      <svg><use xlink:href="#check" /></svg>
      <span v-if="label">{{ label }}</span>
    </span>
    <slot />
  </label>
</template>

<script>
export default {
  name: 'VCheckbox',
  props: {
    value: { type: Boolean, default: false },
    errorBag: { type: Array, default: () => [] },
    isDirty: { type: Boolean, default: () => false },
    label: {
      type: String,
      default: '',
    }
  },
  emits: ['update:modelValue'],
  computed: {
    isChecked () {
      return this.value
    }
  },
  methods: {
    onChange () {
      this.$emit('update:modelValue', !this.value)
    }
  }
}
</script>
