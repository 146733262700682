const signup = {
  state: {
    notifications: {}
  },
  mutations: {
    addNotification: (state, notification) => {
      let key = Date.now()

      if(notification.key){
        key = notification.key
      }
      state.notifications[key] = notification
    },
    removeNotification: (state, key) => {
      if(state.notifications[key]){
        delete state.notifications[key]
      }
    }
  },
  actions: {
    addNotification: ({ commit }, notification) => {
      commit('addNotification', notification)
    },
    removeNotification: ({ commit }, key) => {
      commit('removeNotification', key)
    }
  },
  getters: {
    getNotifications: (state) => {
      return state.notifications
    }
  }
}

export default signup
