<template>
  <div
    id="popup-location"
    class="popup__wrapper popup__wrapper--sm popup__wrapper--location"
    style="display: none"
  >
    <div class="popup__in">
      <div class="text font-medium spacing-4">
        Выберите населенный пункт
      </div>

      <label class="form-field form-field--bg form-field--search spacing-35">
        <input
          v-model="query"
          class="form-field--search__input js-location-input"
          type="text"
          placeholder="Ваш город"
        >
        <svg
          class="form-field--search__icon js-location-search"
          width="24"
          height="24"
        >
          <use xlink:href="#search" />
        </svg>

        <button class="form-field--search__icon-clear js-clear-location-input">
          <svg
            width="24"
            height="24"
          >
            <use xlink:href="#cross" />
          </svg>
        </button>
      </label>

      <div class="d-flex flex-column align-items-start row-gap-8">
        <location-popup-item
          v-for="locationItem in filteredItems"
          :item="locationItem"
          :active="locationItem === selectedItem"
          @change="selectItem(locationItem)"
        />
      </div>
    </div>
  </div>
</template>
<script setup>

import { computed, onMounted, ref } from 'vue'
import LocationPopupItem from '@/components1/common/popups/LocationPopupItem.vue'
import { useStore } from 'vuex'
//todo: механизм с городами переделать!
const items = [
  'Москва и Московская область',
  'Санкт-Петербург',
  'Казань',
  'Уфа',
  'Нижний Новгород',
  'Великий Новгород',
  'Краснодар',
  'Челябинск',
  'Набережные Челны',
]

const query = ref('')
const defaultCity = 'Москва и Московская область'
const city = ref(defaultCity)
const store = useStore()

const selectedItem = computed(() => {
  const arItems = items.filter((i) => {
    return i === city.value
  })

  return arItems[0] || defaultCity
})

const filteredItems = computed(() => {
  if(query.value.length < 1){
    return items
  }

  return items.filter((i) => {
    return i.toUpperCase().indexOf(query.value.toUpperCase()) > -1
  })
})

const fillItem = () => {
  city.value = localStorage.getItem('city') || defaultCity
}

const selectItem = (item) => {
  localStorage.setItem('city', item)
  store.state.userCity = item
  fillItem()
}

onMounted(fillItem)

</script>
