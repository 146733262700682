<template>
  <div
    class="form-field__wrapper spacing-4"
    :class="{
      [outerClass + '']: !!outerClass
    }"
  >
    <div
      class="form-field form-field--small form-field--bg"
      :class="{
        'form-field__error-field': errorText && !!errorBag?.[0] || hasError
      }"
    >
      <textarea
        v-if="attrs?.type === 'textarea'"
        :id="attrs.id"
        v-model.trim="state"
        v-maska="attrs?.maska"
        tabindex="0"
        class="form-field__input"
        placeholder=" "
        :style="inputStyles"
        :maxlength="attrs.v_maxlength"
        :disabled="attrs.disabled"
        @focus="focusHandler"
        @focusout="updateModelValue(state)"
      />
      <input
        v-else
        :id="attrs.id"
        v-model.trim="state"
        v-maska="attrs?.maska"
        tabindex="0"
        class="form-field__input"
        placeholder=" "
        :style="inputStyles"
        :maxlength="attrs.v_maxlength"
        :disabled="attrs.disabled"
        :type="attrs?.type"
        :inputmode="attrs?.inputmode || inputmode || undefined"
        @input="$emit('input', $event)"
        @focus="focusHandler"
        @focusout="updateModelValue(state)"
      >
      <label
        :for="attrs.id"
        class="form-field__label"
      >{{ attrs.label }}<template v-if="attrs.required"> *</template></label>
      <div
        v-if="showSuggestions"
        class="custom-input-suggestions"
      >
        <div
          v-for="(suggest, idx) in suggestions"
          :key="idx"
          class="custom-input-suggestions-item"
          @click="selectSuggest($event, suggest)"
        >
          {{ suggestValue(suggest) }}
        </div>
      </div>
      <p
        v-show="errorText && !!errorBag?.[0]"
        ref="errors"
        class="form-field__error mb-0"
      >
        {{ errorBag?.[0] }}
      </p>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'VInput',
  props: {
    modelValue: { type: String, default: null },
    attrs: {
      type: Object,
      default: () => ({})
    },
    errorBag: {
      type: Array,
      default: () => ([])
    },
    serverErrorBag: {
      type: Array,
      default: () => ([])
    },
    suggestions: {
      type: Array,
      default: () => ([])
    },
    clear: {
      type: Boolean,
      default: true
    },
    errorText: {
      type: Boolean,
      default: true
    },
    hasError: {
      type: Boolean,
      default: false
    },
    outerClass: {
      type: String,
      default: ''
    },
    inputmode: {
      type: String,
      default: undefined
    }
  },
  emits: ['update:modelValue', 'selectSuggest', 'input'],
  data () {
    return {
      focus: false,
      observer: new MutationObserver(this.callback),
      state: null,
    }
  },
  computed: {
    hasErrors () {
      return this.errorBag.length > 0 || this.serverErrorBag.length > 0
    },
    inputStyles () {
      return {
        'max-width': this.attrs.width + 'px',
        height: this.attrs.height + 'px',
        //'padding-right': '100px'
      }
    },
    showSuggestions () {
      return (
        this.focus &&
        this.modelValue?.length > 1 &&
        this.suggestions?.length > 0
      )
    },
  },
  watch: {
    state(newVal) {
      this.state = newVal
    }
  },
  mounted () {
    this.state = this.modelValue
    document.addEventListener('click', this.handleClickOutside)
  },
  unmounted () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    selectSuggest (evt, suggest) {
      // console.log(this.focus);
      setTimeout(() => {
        this.focus = false
      }, 100)
      this.$emit('selectSuggest', { field: this, suggest })
    },
    suggestValue (suggest) {
      return this.attrs.id === 'organization'
        ? suggest?.data?.address?.value
        : suggest.value
    },
    callback (mutationsList) {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          gsap.fromTo(
            this.$refs.errors,
            { opacity: 0, transition: 1000 },
            { opacity: 1, transition: 1000 }
          )
        } else if (mutation.type === 'attributes') {
        }
      }
    },
    clearInput () {
      this.$emit('update:modelValue', '')
      this.$el.control.value = ''
    },
    focusHandler () {
      this.focus = true
    },
    handleClickOutside (event) {
      if (!this.$el.contains(event.target)) {
        this.focus = false
      }
    },
    updateModelValue (val) {
      this.$emit('update:modelValue', val)
    }
  }
}
</script>
<style lang="scss" scoped>
textarea{
  display: block;
}
</style>
