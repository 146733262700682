<template>
  <router-link
    :to="isAuth ? '/loan' : '/signin'"
    tag="button"
    class="button button-icon button-secondary button--sm"
  >
    <svg
      width="24"
      height="24"
    >
      <use xlink:href="#cabinet" />
    </svg>
    <span>Войти</span>
  </router-link>
  <router-link
    v-if="!isAuth"
    to="/signup"
    tag="button"
    class="button button-icon button-primary ml-2 button--sm flex-grow-1"
  >
    <span>Регистрация</span>
  </router-link>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const { getters } = useStore()

const isAuth = computed(() => getters['USER'] !== null && getters['TOKEN']?.length > 2)

</script>
