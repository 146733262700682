import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'default',
      title: 'Смартзайм - Главная',
      auth: false,
      name: 'Главная'
    },
    component: () =>
      import(/* webpackChunkName: 'home' */ '../pages/HomePage.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    meta: {
      layout: 'auth',
      title: 'Смарт займ - Подтверждение телефона',
      auth: false,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../pages/SignupPage.vue')
  },
  {
    path: '/signin',
    name: 'signin',
    meta: {
      layout: 'auth',
      title: 'Смарт займ - Подтверждение телефона',
      auth: false,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../pages/SigninPage.vue')
  },
  {
    path: '/reset',
    name: 'reset',
    meta: {
      layout: 'auth',
      title: 'Смарт займ - Сброс пароля',
      auth: false,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../pages/ResetPage.vue')
  },
  {
    path: '/confirmRegistration',
    name: 'confirmRegistration',
    meta: {
      layout: 'auth',
      title: 'Смарт займ - Подтверждение смс',
      auth: false,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../pages/ConfirmRegistrationPage.vue')
  },
  {
    path: '/otl',
    name: 'oneTimeCode',
    meta: {
      layout: 'auth',
      title: 'Смарт займ - Подтверждение смс',
      auth: false,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../pages/OneTimeCodePage.vue')
  },

  {
    path: '/profile/identity',
    name: 'profileIdentity',
    meta: {
      layout: 'profile',
      title: 'Смарт займ - идентификация профиля',
      auth: true,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../pages/ProfileIdentificationPage.vue')
  },
  {
    path: '/profile/order',
    name: 'profileOrder',
    meta: {
      layout: 'profile',
      title: 'Смарт займ - идентификация профиля',
      auth: true,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../pages/ProfileOrderPage.vue')
  },
  {
    path: '/pay/callback',
    name: 'pay/callback',
    meta: {
      layout: 'profile',
      title: '',
      auth: false,
      name: 'callback'
    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/PayCallback.vue')
  },
  {
    path: '/pay/loanpay',
    name: 'pay/loanpay',
    meta: {
      layout: 'default',
      title: '',
      auth: false,
      name: 'callback'
    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/LoanPay.vue')
  },
  {
    path: '/profile/checkOrder',
    name: 'profileCheckOrder',
    meta: {
      layout: 'profile',
      title: 'Смарт займ - Главная',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../pages/ProfileCheckOrderPage.vue')
  },
  {
    path: '/profile/confirmOrder',
    name: 'confirmOrder',
    meta: {
      layout: 'profile',
      title: 'Смарт займ - Главная',
      auth: true,

    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../pages/ProfileContractConfirm.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      layout: 'cabinet',
      title: 'Смарт займ - Мой профиль',
      auth: true,
      token: 'client',
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../pages/ProfilePage.vue')
  },
  {
    path: '/new-loan',
    name: 'new-loan',
    meta: {
      layout: 'cabinet',
      title: 'Смарт займ - Новый займ',
      auth: true,
      name: 'Новый займ'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../pages/NewLoanPage.vue')
  },
  {
    path: '/eary-repayment/:id',
    name: 'eary-repayment',
    meta: {
      layout: 'cabinet',
      title: 'Смарт займ - Досрочное погашение',
      auth: true,
      name: 'Досрочное погашение'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../pages/EarlyRepaymentPage.vue')
  },
  {
    path: '/profile/registration',
    name: 'registration',
    meta: {
      layout: 'cabinet',
      title: 'Смарт займ - Моя регистрация',
      auth: true,
      token: 'client',
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../pages/RegistrationPage.vue')
  },
  {
    path: '/profile/car',
    name: 'car',
    meta: {
      layout: 'cabinet',
      title: 'Смарт займ - Мой автомобиль',
      auth: true,
      token: 'client',
      name: 'Мой автомобиль'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../pages/CarPage.vue')
  },
  {
    path: '/profile/passport',
    name: 'passport',
    meta: {
      layout: 'cabinet',
      title: 'Смарт займ - Мой паспорт',
      auth: true,
      token: 'client',
      name: 'Мой паспорт'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../pages/PassportPage.vue')
  },
  {
    path: '/profile/work',
    name: 'work',
    meta: {
      layout: 'cabinet',
      title: 'Смарт займ - Моя работа',
      auth: true,
      token: 'client',
      name: 'Моя работа'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../pages/PortfolioPage.vue')
  },
  {
    path: '/loan',
    name: 'loanlist',
    meta: {
      layout: 'cabinet',
      title: 'Смарт займ - Список займов',
      auth: true,
      token: 'client',
      name: 'Список займов'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../pages/LoanListPage.vue')
  },
  {
    path: '/loan/:id',
    name: 'loan',
    meta: {
      layout: 'cabinet',
      title: 'Смарт займ - Текущий займ',
      auth: true,
      token: 'client',
      name: 'Текущий займ'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../pages/LoanPage.vue')
  },
  {
    path: '/appeals',
    name: 'appeals',
    meta: {
      layout: 'cabinet',
      title: 'Смарт займ - Мои обращения',
      auth: true,
      token: 'client',
      name: 'Мои обращения'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../pages/AppealsPage.vue')
  },
  {
    path: '/history',
    name: 'history',
    meta: {
      layout: 'dashboard',
      title: 'Смарт займ - История займов',
      auth: false,
      name: 'История займов'
    },
    component: () =>
      import(/* webpackChunkName: 'history' */ '../views/HistoryView.vue')
  },
  {
    path: '/documents',
    name: 'documents',
    meta: {
      layout: 'dashboard',
      title: 'Смарт займ - Документы',
      auth: false,
      name: 'Документы'
    },
    component: () =>
      import(/* webpackChunkName: 'documents' */ '../views/DocumentsView.vue')
  },
  {
    path: '/files',
    name: 'files',
    meta: {
      layout: 'dashboard',
      title: 'Смарт займ - Файлы',
      auth: false,
      name: 'Файлы'
    },
    component: () =>
      import(/* webpackChunkName: 'files' */ '../views/FilesView.vue')
  },
  {
    path: '/qr',
    name: 'qr',
    meta: {
      layout: 'default',
      title: 'Смарт займ - Файлы',
      auth: false,
      name: 'Файлы'
    },
    component: () =>
      import(/* webpackChunkName: 'qr' */ '../views/QrView.vue')
  },
  {
    path: '/media/blog/:blogPostId',
    name: 'blogPost',
    meta: {
      layout: 'dynamic',
      title: 'Смартзайм - Блог',
      auth: false,
      name: ''
    },
    component: () =>
      import(/* webpackChunkName: 'home' */ '../pages/DynamicPage.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'dynamicPage',
    meta: {
      layout: 'dynamic',
      title: 'Смартзайм',
      auth: false,
      name: ''
    },
    component: () =>
      import(/* webpackChunkName: 'home' */ '../pages/DynamicPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  const currentQuery = { ...to.query }
  const tokenFromCRM = from.query.token || to.query.token
  const userFromCRM = from.query.user_id || to.query.user_id
  const fromCRM = from.query.from_crm || to.query.from_crm
  const requireAuth = to.matched.some((record) => record.meta.auth)
  const token = store.getters.TOKEN || localStorage.getItem('token') || null
  const isAuth = store.getters['IS_DASHBOARD']

  const newQuery = { ...currentQuery }

  store.commit('SET_PAGE_LOADING', true)

  if (fromCRM == 'true' && tokenFromCRM && userFromCRM) {
    store.dispatch('SET_TOKEN', tokenFromCRM)
    store.dispatch('SET_IS_ADMIN', true)
    store.dispatch('GET_USER_INFO')
    newQuery.token = tokenFromCRM
  }

  if (requireAuth) {
    if (!token) {
      store.dispatch('addNotification', {
        message: 'Токен доступа истёк, пройдите авторизацию повторно'
      })
      next('/signin')

      return
    }

    if (['signin', 'signup'].includes(to.name) && token && isAuth) {
      next('/loan')

      return
    }

    next()

    return

  } else {

    if (to.hash) {
      const elementId = to.hash.replace('#', '')
      const element = document.getElementById(elementId)

      if (element) {
        next(false)

        return
      }
    }
  }
  next()

})

router.afterEach(() => {
  const body = document.querySelector('body')

  if (body) {
    body.classList.remove('is-loading')
  }
})

export default router
