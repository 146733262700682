import apiRequest from '../../middlewares/api-request'
import axios from 'axios'
import store from '@/store'

const signup = {
  state: {
    headerShown: true,
    is_dashboard: false,
    user: null,
    is_not_mobile_registration: false,
    client_id: '',
    active_orders: [],
    orders: [],
    files: [],
    active_payout_methods: [],
    active_payment_methods: [],
    spb_banks: [],
    history: [],
    documents: [],
    order_documents: [],
    photos: [],
    order: null,
    return_sum: 0,
    selected_order: null,
    formFields: null,
    lk_cards: [],
    prolongation_documents: [],
    contract_documents: [],
    close_loan_documents: [],
    sign_documents: [
      {
        link: 'https://crm.acado.market/documents/static_documents/Правила_предоставления_услуги_быть_в_курсе.pdf',
        name: 'Правила оказания услуги «Будь в курсе!»',
        required: false,
        match: [],
        errorBag: []
      },
      {
        link: 'https://crm.acado.market/documents/static_documents/Правила_абонентского_обслуживания_Онлайн_консультации_с_врачом.pdf',
        name: 'Правила абонентского обслуживания (Онлайн консультация с врачом) – Телемедицина',
        required: false,
        match: [],
        errorBag: []
      }
    ],
    admin: false
  },
  mutations: {
    SET_IS_DASHBOARD: (state, flag) => {
      state.is_dashboard = flag
    },
    ADD_SELECTED_ORDER: (state, order) => {
      state.selected_order = order
    },
    SET_SELECTED_ORDER: (state, res) => {
      state.selected_order = res.data.data.data
    },
    SET_RETURN_SUM: async (state, { sum }) => {
      state.return_sum = sum
    },
    SET_USER_INFO: async (state, { data }) => {
      state.user = data.data?.client
    },
    SET_FILES: async (state, { data }) => {
      state.files = data.data.files
    },
    SET_ACTIVE_ORDERS: (state, res) => {
      if (res?.data?.data?.data?.amount) {
        state.active_orders = [res.data.data.data]
      } else state.active_orders = []
    },
    SET_ORDERS: (state, res) => {
      state.orders = res.data.data.data
    },
    SET_NOT_MOBILE_REGISTRATION: (state, isNot) => {
      state.is_not_mobile_registration = isNot
    },
    SET_PAYOUT_METHODS: (state, res) => {
      if (res?.data.length > 0) {
        state.active_payout_methods = [...res.data]
      } else {
        state.active_payout_methods = []
      }
    },
    SET_PAYMENT_METHODS: (state, res) => {
      if (res?.data.length > 0) {
        state.active_payment_methods = [...res.data]
      } else {
        state.active_payment_methods = []
      }
    },
    SET_SBP_BANKS: (state, res) => {
      if (res?.data.data.banks.length > 0) {
        state.spb_banks = [...res.data.data.banks]
      } else {
        state.spb_banks = []
      }
    },
    SET_HISTORY: (state, res) => {
      res.data.data.data.map((item) => {
        item.time = item.created_at?.split(' ')?.[1]
        const [year, month, day] = item.created_at.split(/[^\d]/)
        const str = `${day}.${month}.${year.slice(-2)}`

        item.created_at = str

        return item
      })
      state.history = res.data.data.data
    },
    SET_HEADER_SHOWN: (state, res) => {
      state.headerShown = !!res
    },
    SET_DOCUMENTS: (state, res) => {
      const stopTypes = [
        'personal_data_consent',
        'rules_rejection_reason',
        'rejection_reason_application',
        'rules_rejection_reason',
        'asp_agreement',
        'analogue_signature_agreement',
        'service_agreement',
        'loan_granting_rules'
      ]

      // console.log(test2);
      // test2.map((item) => {
      //   console.log(item);
      // });
      // let test;
      // test = filtred_arr.sort((a, b) => a.order - b.order);
      state.documents = res?.data?.data?.data.map((docList) => {
        return docList.filter((doc) => !stopTypes.includes(doc?.type))
      })
    },
    // SET_ORDER_DOCUMENTS: (state, res) => {
    //   state.order_documents = res.data.data.data;
    // },
    CHECK_PHOTO: (state, { id, type, params }) => {
      // let type = params?.[0]?.split('"')?.[3];
      let status = params?.status

      switch (type) {
      case 'selfie':
        type = 'Селфи с паспортом'
        break
      case 'passport':
        type = 'Первая страница паспорта'

        break
      case 'reg_address':
        type = 'Страница регистрации'
        break
      case 'card_photo':
        type = 'Фото банковской карты'
        break
      default:
        break
      }

      // statusses
      // refuse, pending, accept
      if (!status) {
        status = 'pending'
      }

      state.photos.push({
        url: `${process.env.VUE_APP_API_URL}api/files/get/${id}/auth`,
        type,
        id,
        status: status,
        initial_type: params?.type
      })
      // state.photos.map(item => {
      //   if (item.)
      // })
    },
    SET_CARDS: (state, cards) => {
      state.lk_cards = cards
    },
    SET_ADMIN: (state, flag) => {
      state.admin = flag
    },
    SET_PROLONGATION_DOCUMENTS: (state, docs) => {
      state.prolongation_documents = []
      if (docs === undefined) return
      let filtred_docs
      const docsToPush = docs
        .filter(
          (item) =>
            !item?.title.includes('Сертификат') &&
            !item?.title.includes('Справка о закрытии займа')
        )
        .map((item) => {
          if (
            item?.type === 'subscription_service_rules_telejur' ||
            item?.type === 'application_for_the_services_of_telejurist'
          ) { item.required = false } else if (item.required !== undefined) item.required
          else item.required = true

          item.link = item.link !== undefined ? item.link : `https://crm.acado.market/document/${item?.id}`
          item.name = item?.title
          item.match = []
          item.errorBag = []

          return item
        })

      filtred_docs = docsToPush.sort((a, b) => a.order - b.order)
      state.prolongation_documents = filtred_docs
    },
    SET_CONTRACT_DOCUMENTS: (state, docs) => {
      state.contract_documents = []
      if (docs === undefined) return
      let filtred_docs
      const docsToPush = docs
        .filter(
          (item) =>
            !item?.title.includes('Сертификат') &&
            !item?.title.includes('Справка о закрытии займа') &&
            item?.type !== 'rejection_reason_application' &&
            item?.type !== 'rules_rejection_reason' &&
            item?.type !== 'insurance_certificate'
        )
        .map((item) => {
          if (
            item?.type === 'subscription_service_rules_doctor' ||
            item?.type === 'rules_rejection_reason' ||
            item?.type === 'rejection_reason_application' ||
            item?.type === 'consultation_with_doctor_application'
          ) { item.required = false } else if (item.required !== undefined) item.required
          else item.required = true

          item.link !== undefined ? item.link : `https://crm.acado.market/document/${item?.id}`
          item.name = item?.title
          item.match = []
          item.errorBag = []

          return item
        })

      filtred_docs = docsToPush.sort((a, b) => a.order - b.order)
      state.contract_documents = filtred_docs
    },
    SET_CLOSE_LOAN_DOCUMENTS: (state, docs) => {
      state.close_loan_documents = []
      if (docs === undefined) return
      let filtred_docs
      const docsToPush = docs
        .filter(
          (item) =>
            !item?.title.includes('Сертификат') &&
            !item?.title.includes('Справка о закрытии займа')
        )
        .map((item) => {
          if (
            item?.type === 'subscription_service_rules_doctor' ||
            item?.type === 'consultation_with_doctor_application'
          ) { item.required = false } else if (item.required !== undefined) item.required
          else item.required = true

          item.link = item.link !== undefined ? item.link : `https://crm.acado.market/document/${item?.id}`
          item.name = item?.title
          item.match = []
          item.errorBag = []

          return item
        })

      filtred_docs = docsToPush.sort((a, b) => a.order - b.order)
      state.close_loan_documents = filtred_docs
    },
    SET_ORDER_DOCUMENTS: (state, docs) => {
      state.order_documents = []
      if (docs === undefined) return
      let filtred_docs
      const docsToPush = docs
        .filter(
          (item) =>
            !item?.title.includes('Сертификат') &&
            !item?.title.includes('Справка о закрытии займа') &&
            item?.type !== 'rules_rejection_reason' &&
            item?.type !== 'rejection_reason_application'
        )
        .map((item) => {
          if (
            item?.type === 'subscription_service_rules_doctor' ||
            item?.type === 'consultation_with_doctor_application' ||
            item?.type === 'subscription_service_rules_telejur' ||
            item?.type === 'rejection_reason_application'
          ) { item.required = false } else if (item.required !== undefined) item.required
          else item.required = true

          item.link !== undefined ? item.link : `https://crm.acado.market/document/${item?.id}`
          item.name = item?.title
          item.match = []
          item.errorBag = []

          return item
        })

      filtred_docs = docsToPush.sort((a, b) => a.order - b.order)
      state.order_documents = filtred_docs
    },
    SET_FORM_FIELDS: (state, data) => {
      state.formFields = data?.data?.data?.fields
    },
    SET_ORDER: async (state, { data }) => {
      state.order = data.data.client.order
    }
  },
  actions: {
    SET_IS_ADMIN: ({ commit }, flag) => {
      localStorage.setItem('ADMIN', flag)
      commit('SET_ADMIN', flag)
    },
    UPDATE_IS_ADMIN: ({ commit }) => {
      if (localStorage.getItem('ADMIN') === true) {
        commit('SET_ADMIN', localStorage.getItem('ADMIN'))
      }
    },
    ADD_SELECTED_ORDER: ({ commit }, order) => {
      commit('ADD_SELECTED_ORDER', order)
    },
    async SAVE_JOB_INFO ({ commit }, { payload }) {
      return await apiRequest.function(
        'PATCH',
        'api/clients/job',
        null,
        null,
        payload,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async SAVE_REGISTRATION_INFO ({ commit }, { client, payload }) {
      return await apiRequest.function(
        'PATCH',
        `api/clients/${client}/registration`,
        null,
        null,
        payload,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_CLIENT_FROM_SMS_LINK ({ commit }, { client, code }) {
      return new Promise((resolve) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}api/clients/${client}/${code}`)
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            resolve(err?.response)
          })
      })
    },
    async REBID ({ commit }, { client_id, period, amount }) {
      const date = new Date()

      const timezoneOffset = date.getTimezoneOffset()

      const timezone = -(timezoneOffset / 60)

      return new Promise((resolve) => {
        const res = axios
          .post(`${process.env.VUE_APP_API_URL}api/clients/confirm`, {
            client_id,
            amount,
            period,
            timezone
          })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            if (error?.response?.status === 422) {
              this.dispatch('addNotification', {
                message: error?.response?.data?.message
              })
            }
            resolve(error?.response)
          })
      })
    },
    SET_TOKEN({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    async LOGIN ({ commit }, { phone, password }) {
      return new Promise((resolve) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}api/clients/login`, {
            phone,
            password
          })
          .then((res) => {
            if (res?.data?.code === 200) {
              commit('SET_TOKEN', res.data.data.token)
              commit('SET_IS_DASHBOARD', true)
            }
            resolve(res)
          })
          .catch((error) => {
            if (error?.response?.status === 422) {
              this.dispatch('addNotification', {
                message: error?.response?.data?.message
              })
            }
            resolve(error?.response)
          })
      })
    },
    async RESET ({ commit }, { phone }) {
      return new Promise((resolve) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}api/clients/reset`, { phone })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            if (error?.response?.status === 422) {
              this.dispatch('addNotification', {
                message: error?.response?.data?.message
              })
            }
            resolve(error?.response)
          })
      })
    },
    async LOGOUT ({ commit }) {
      await commit('CLEAR_AUTH_CLIENT')
      await commit('CLEAR_CLIENT_ID')
      await commit('CLEAR_TOKEN')
      await commit('SET_AUTH_CLIENT', false)
      localStorage.removeItem('userId')
      localStorage.removeItem('phone')
    },
    async GET_USER_INFO () {
      return await apiRequest.function(
        'GET',
        'api/clients',
        ['SET_USER_INFO', 'SET_FORM_FIELDS', 'SET_ORDER'],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_AMOUNT_FEE ({ commit }, { cardId, amount }) {
      return await apiRequest.function(
        'POST',
        'api/pay/fee',
        [],
        null,
        {
          card_id: cardId,
          amount: amount
        },
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async CREATE_NEW_ORDER ({ commit }, client) {
      return await apiRequest.function(
        'GET',
        `api/clients/${client}/create_new_order`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async SEND_SMS_GO_TO_MOBILE ({ commit }) {
      return await apiRequest.function(
        'POST',
        'api/clients/one-time-link',
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_FILES () {
      return await apiRequest.function(
        'GET',
        'api/files',
        ['SET_FILES'],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_ACTIVE_ORDERS () {
      return await apiRequest.function(
        'GET',
        'api/clients/active_order',
        ['SET_ACTIVE_ORDERS'],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_ORDERS () {
      return await apiRequest.function(
        'GET',
        'api/clients/orders',
        ['SET_ORDERS'],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_SELECTED_ORDER (state, order) {
      return await apiRequest.function(
        'GET',
        `api/clients/orders/${order}`,
        ['SET_SELECTED_ORDER'],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_PAYOUT_METHODS ({ commit }) {
      return await apiRequest.function(
        'GET',
        'api/payment_system/payout',
        ['SET_PAYOUT_METHODS'],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_SBP_QR({ commit }, payload) {
      let link = `api/pay/payment/${payload.contract}`

      link += `?prolongation=${payload.prolongation}&payment_system_id=${payload.payment_system_id}`

      if (payload?.amount) link += `&amount=${payload?.amount}`
      for (let key in payload) {

      }

      return await apiRequest.function(
        'GET',
        link,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_PAYMENT_METHODS ({ commit }) {
      return await apiRequest.function(
        'GET',
        'api/payment_system/payment',
        ['SET_PAYMENT_METHODS'],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_SBP_BANKS({ commit }) {
      return await apiRequest.function(
        'GET',
        'api/banks',
        ['SET_SBP_BANKS'],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_HISTORY () {
      return await apiRequest.function(
        'GET',
        'api/clients/credit_history',
        ['SET_HISTORY'],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_DOCUMENTS () {
      return await apiRequest.function(
        'GET',
        'api/clients/documents',
        ['SET_DOCUMENTS'],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_PHOTOS ({ commit }, file) {
      commit('CHECK_PHOTO', file)
    },
    async GET_CARDS ({ commit }) {
      const res = await apiRequest.function(
        'GET',
        'api/clients/cards',
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )

      commit('SET_CARDS', res?.data?.data?.cards)
    },
    async PAY_LOAN ({ commit }, payload) {
      let link = `api/pay/payment/${payload.contract}`

      link += `?card_id=${payload.card_id}&type=${payload.type}&payment_system_id=${payload.payment_system_id}`
      if (payload?.prolongation) { link += `&prolongation=${payload.prolongation}` }

      if (payload?.amount) link += `&amount=${payload?.amount}`

      const res = await apiRequest.function('GET', link, null, null, payload, {
        Authorization: `Bearer ${this.getters.TOKEN}`
      })

      return res
    },
    async SEND_LOAN_PAY_CALLBACK ({ commit }, { search, client }) {
      const res = await apiRequest.function(
        'GET',
        `api/pay/callbackPayment${search}`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )

      if (res == undefined) return
      const { data, code = 400, message } = res.data

      if (code === 200) {
        // commit("SET_TOKEN", res.data.data.token);
      }

      return res
    },
    async GET_DASHBOARD_BIND_CARD_LINK ({ commit }, client) {
      return await apiRequest.function(
        'GET',
        'api/pay/link',
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_DASHBOARD_BIND_CARD_LINK_AUTH ({ commit }, client) {
      return await apiRequest.function(
        'GET',
        'api/pay/link',
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GENERATE_ASP_CODE ({}, { client_id, asp_type, order_id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}api/clients/generate_asp`,
            {
              asp_type,
              order_id,
              client_id
            },
            {
              headers: {
                Authorization: 'Bearer ' + this.getters.TOKEN
              }
            }
          )
          .then((res) => {
            resolve(res)
          })
      })
    },
    async SIGN_ASP_CODE ({}, { asp_code, order_id, route }) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}api/clients/${route}`,
            {
              asp_code,
              order_id
            },
            {
              headers: {
                Authorization: 'Bearer ' + this.getters.TOKEN
              }
            }
          )
          .then((res) => {
            resolve(res)
          })
      })
    },
    async PROLONGATION_SIGN ({}, { order_id, asp_code }) {
      return new Promise((resolve) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}api/clients/prolongation`,
            { order_id, asp_code },
            {
              headers: {
                Authorization: 'Bearer ' + this.getters.TOKEN
              }
            }
          )
          .then((res) => {
            resolve(res)
          })
      })
    },
    async GET_UNSIGNED_DOCS ({ commit }) {
      return new Promise((resolve) => {
        axios
          .get(
            `${process.env.VUE_APP_API_URL}api/clients/unsigned_documents`,
            {
              headers: {
                Authorization: 'Bearer ' + this.getters.TOKEN
              }
            }
          )
          .then((res) => {
            const allDocs = res?.data?.data?.data

            commit('SET_PROLONGATION_DOCUMENTS', allDocs.PROLONGATION)
            commit('SET_CONTRACT_DOCUMENTS', allDocs.CONTRACT)
            commit('SET_CLOSE_LOAN_DOCUMENTS', allDocs.CONTRACT_CLOSE)
            commit('SET_ORDER_DOCUMENTS', allDocs?.ORDER || [])
            resolve(res)
          })
      })
    },
    async GET_CONFIRM_CODES ({}, payload) {
      return await apiRequest.function(
        'POST',
        'api/sms/check_attempt',
        null,
        null,
        payload,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_CONFIRM_CODES_UN_AUTHORIZED ({}, payload) {
      return await apiRequest.function(
        'POST',
        'api/sms/check_attempt_una',
        null,
        null,
        payload,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async SEND_NEW_CODE({}, payload) {
      return await apiRequest.function(
        'POST',
        'api/sms/send',
        null,
        null,
        payload,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async SEND_NEW_CODE_UN_AUTHORIZED({}, payload) {
      return await apiRequest.function(
        'POST',
        'api/sms/send_una',
        null,
        null,
        payload,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async GET_ONE_TIME_LINK ({}, payload) {
      return await apiRequest.function(
        'POST',
        'api/clients/one-time-link',
        null,
        null,
        payload,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    },
    async CHECK_ONE_TIME_LINK ({}, code) {
      return await apiRequest.function(
        'GET',
        'api/otl?one_time_link='+code,
        null,
        null,
        null,
        {}
      )
    },
    async UPDATE_ORDER({}, { order, id }) {
      return await apiRequest.function(
        'PATCH',
        `api/orders/${id}`,
        null,
        null,
        order,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      )
    }
  },
  getters: {
    HEADER_SHOWN (state) {
      return state.headerShown
    },
    GET_RETURN_SUM (state) {
      return state.return_sum
    },
    IS_DASHBOARD (state) {
      return state.is_dashboard
    },
    PROLONGATION_DOCUMENTS (state) {
      return state.prolongation_documents
    },
    CONTRACT_DOCUMENTS (state) {
      return state.contract_documents
    },
    CLOSE_LOAN_DOCUMENTS (state) {
      return state.close_loan_documents
    },
    USER (state) {
      return state.user
    },
    FILES (state) {
      return state.files
    },
    ORDER (state) {
      return state.order
    },
    ACTIVE_ORDERS (state) {
      return state.active_orders
    },
    IS_NOT_MOBILE_REGISTRATION (state) {
      return state.is_not_mobile_registration
    },
    ORDERS (state) {
      return state.orders
    },
    SELECTED_ORDER (state) {
      return state.selected_order
    },
    ACTIVE_PAYOUT_METHODS (state) {
      return state.active_payout_methods
    },
    ACTIVE_PAYMENT_METHODS (state) {
      return state.active_payment_methods
    },
    ACTIVE_SBP_BANKS (state) {
      return state.spb_banks
    },
    HISTORY (state) {
      return state.history
    },
    DOCUMENTS (state) {
      return state.documents
    },
    ORDER_DOCUMENTS (state) {
      return state.order_documents
    },
    PHOTOS (state) {
      return state.photos
    },
    LK_CARDS (state) {
      return state.lk_cards
    },
    ADMIN (state) {
      return state.admin
    },
    SIGN_DOCUMENTS (state) {
      return state.sign_documents
    },
    FORM_FIELDS (state) {
      if (state.formFields === null || state.user === null) {
        return []
      }

      const fields = Object.entries(state.formFields).map( g => (g[1])).flat(1)

      /** собираем массив из полученных полей из конструктора */
      fields.forEach(field => {
        field['error'] = []

        const hasRelation = !!(field['relation_field'] && field['relation_field']['relation'])
        const isClientGroup = field['relation_field']['relation'] === 'client'
        const isContactPersonGroup = field['relation_field']['relation'] === 'contact_persons'

        if (isClientGroup) {
          field['value'] = state.user[field['relation_field']['column']]

          return
        }
        if (hasRelation && hasRelation) {
          field['value'] = state.user[field['relation_field']['relation']]?.[field['relation_field']['column']] || null
        }
        if (hasRelation && field['relation_field']['relation'] === 'extended') {
          const index = state.user.extended.findIndex( f => f['field_id'] === field.id)

          if (index !== -1) {
            field['value'] = state.user['extended'][index]['value'] || null
          }
        }
        if (hasRelation && isContactPersonGroup && state.user[field['relation_field']['relation']].length) {
          const value = state.user[field['relation_field']['relation']][0][field['relation_field']['column']]

          if (value) {
            field['relation_id'] = state.user[field['relation_field']['relation']][0]['id']
          }
          field['value'] = value || null
        }
      })

      return fields
    }
  }
}

export default signup
