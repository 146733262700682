<template>
  <header
    class="header"
    :class="{
      'compact': !isHeaderShown
    }"
  >
    <div
      v-if="isHeaderShown"
      class="header-content"
    >
      <div class="header-top__wrapper">
        <div class="container-fluid">
          <div class="header-top d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <div class="header-top__left d-flex align-items-center gap-32">
                <button
                  class="link"
                  data-fancybox
                  data-src="#popup-location"
                >
                  <svg>
                    <use xlink:href="#location" />
                  </svg>
                  <span>{{ userCity }}</span>
                </button>
                <router-link
                  to="/vacancy"
                  class="link d-none d-lg-inline-block"
                >
                  <svg class="mr-1">
                    <use xlink:href="#case" />
                  </svg>
                  <span>Вакансии</span>
                </router-link>
              </div>
            </div>

            <div
              v-if="false"
              class="d-flex align-items-center gap-24"
            >
              <div class="header-messenger__wrapper">
                <HeaderMessenger v-if="!isTablet" />
              </div>

              <button
                class="link"
                data-fancybox
                data-src="#popup-call"
              >
                <svg>
                  <use xlink:href="#tel" />
                </svg>
                <span>Заказать звонок</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="header-bottom__wrapper">
        <div class="container-fluid">
          <div class="header-bottom">
            <div class="d-flex align-items-center justify-content-between justify-content-sm-start logo-wrapper">
              <button class="burger js-menu-toggle order-2 order-sm-1">
                <svg><use xlink:href="#burger" /></svg>
                <svg><use xlink:href="#cross-1" /></svg>
              </button>

              <span
                v-if="isMP"
                class="header-logo order-1 order-sm-2"
              >
                <svg
                  width="174"
                  height="48"
                >
                  <use xlink:href="#logo" />
                </svg>
              </span>
              <router-link
                v-else
                to="/"
                class="header-logo order-1 order-sm-2"
              >
                <svg
                  width="174"
                  height="48"
                >
                  <use xlink:href="#logo" />
                </svg>
              </router-link>
            </div>
            <div class="header-nav__wrapper">
              <top-menu v-if="!isTablet" />
            </div>
            <div class="d-none d-sm-flex">
              <LogInButtons />
            </div>
          </div>
        </div>
      </div>

      <top-mobile-menu :is-tablet="isTablet" />
    </div>
    <div
      v-else
      class="header-content"
    >
      <div class="header-bottom__wrapper">
        <div class="container">
          <div class="header-bottom">
            <div class="d-flex align-items-center justify-content-between justify-content-sm-start logo-wrapper">
              <router-link
                to="/"
                class="header-logo order-1 order-sm-2"
              >
                <svg
                  width="174"
                  height="48"
                >
                  <use xlink:href="#logo" />
                </svg>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, inject } from 'vue'
import { useStore } from 'vuex'

import TopMenu from '@/components1/common/TopMenu.vue'
import TopMobileMenu from '@/components1/common/TopMobileMenu.vue'
import HeaderMessenger from '@/components1/common/HeaderMessenger.vue'
import LogInButtons from '@/components1/common/LogInButtons.vue'

const { getters, state } = useStore()
const $ = inject('$')
const defaultCity = 'Москва и Московская область'
const isTablet = ref(false)

const isHeaderShown = computed(() => {
  return getters['HEADER_SHOWN']
})

const updateIsTablet = () => {
  isTablet.value = $(window).width() < 1300
}

//todo: механизм с городами переделать!
const userCity = computed(() => {
  return getters['USER_CITY'] || defaultCity
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateIsTablet)
})

const initHeaderSticky = () => {
  if(!isHeaderShown.value){
    $('.header').removeClass('sticky')

    return
  }
  if ($(window).scrollTop() > 5){
    $('.header').addClass('sticky')
  } else {
    $('.header').removeClass('sticky')
  }
}

onMounted(() => {
  state.userCity = localStorage.getItem('city') || defaultCity

  updateIsTablet()
  window.addEventListener('resize', updateIsTablet)
  $(window).scroll(function() {
    initHeaderSticky()
  })
})

const isMP = computed(() => {
  return window.location.pathname === '/'
})

</script>

<style scoped lang="scss">
.logo-wrapper{
  width: 100%;
  @media(min-width: 576px){
    width: unset;
  }
}
.header.compact{
  position: relative!important;
  .header-bottom__wrapper{
    border-bottom: none!important;
  }
}
</style>
