<template>
  <header class="v-header">
    <div class="v-header__container">
      <div class="v-header__nav">
        <div
          ref="menu"
          class="v-header__burger"
          @click="toggleMenu"
        >
          <span class="v-header__burger-line" />
          <span class="v-header__burger-line" />
          <span class="v-header__burger-line" />
        </div>

        <div
          class="v-header__menu"
          :class="{ 'v-header__menu_active': menuToggle }"
        >
          <a
            href="#getLoan"
            class="v-header__link"
            @click="$router.push({ path: '/', hash: '#getLoan' })"
          >
            Как получить займ
          </a>
          <a
            href="#about"
            class="v-header__link"
            @click="$router.push({ path: '/', hash: '#about' })"
          >
            О компании
          </a>
          <a
            href="#getLoan"
            class="v-header__link"
            @click="$router.push({ path: '/', hash: '#getLoan' })"
          >
            Условия займа
          </a>
          <a
            href="#about"
            class="v-header__link"
            @click="$router.push({ path: '/', hash: '#about' })"
          >
            FAQ
          </a>

          <a
            class="v-header__actions-link v-header__actions-link_tel"
            href="tel:+79123456789"
          >+7 (912) 345-67-89</a>
          <a
            class="v-header__actions-link v-header__actions-link_mail"
            href="mailto:info@creditboost.com"
          >info@creditboost.com</a>

          <v-btn
            class="v-header__lk"
            @click="toggleMenu(), openDialog('signin_dialog')"
          >
            Личный кабинет
          </v-btn>
          <v-btn class="v-header__payment">
            Внести платеж
          </v-btn>
        </div>

        <v-icon
          class="v-header__logo"
          :icon="require(`@/assets/svg/logo.svg`)"
          @click="$router.push({ name: 'home' })"
        />

        <div class="v-header__links">
          <a
            href="#getLoan"
            class="v-header__link"
            @click="$router.push({ path: '/', hash: '#getLoan' })"
          >
            Как получить займ
          </a>
          <a
            href="#about"
            class="v-header__link"
            @click="$router.push({ path: '/', hash: '#about' })"
          >
            О компании
          </a>
          <a
            href="#getLoan"
            class="v-header__link"
            @click="$router.push({ path: '/', hash: '#getLoan' })"
          >
            Условия займа
          </a>
          <a
            href="#about"
            class="v-header__link"
            @click="$router.push({ path: '/', hash: '#about' })"
          >
            FAQ
          </a>
        </div>

        <div class="v-header__right">
          <div class="v-header__actions">
            <div class="v-header__actions-links">
              <a
                class="v-header__actions-link v-header__actions-link_tel"
                href="tel:+79123456789"
              >+7 (912) 345-67-89</a>
              <a
                class="v-header__actions-link v-header__actions-link_mail"
                href="mailto:info@creditboost.com"
              >info@creditboost.com</a>
            </div>

            <v-btn
              class="v-header__lk"
              @click="toggleMenu(), openDialog('signin_dialog')"
            >
              Личный кабинет
            </v-btn>
          </div>
          <v-btn class="v-header__payment">
            Внести платеж
          </v-btn>
          <v-btn
            v-if="!authorized"
            class="v-header__auth"
            :is-tranparent="true"
            @click="authButtonHandler"
          >
            Войти
          </v-btn>
          <div
            v-else
            class="v-header__user-badge"
          >
            {{ userAlias }}
          </div>
        </div>
      </div>
    </div>

    <AuthForm
      v-if="signin_dialog.visible"
      @reset="showResetDialog"
      @close="signin_dialog.visible = false"
      @submit="submitAuthForm"
    />

    <v-popup
      class="signin__dialog"
      :visible="reset_dialog.visible"
      @close-popup="closeDialog('reset_dialog')"
    >
      <template #title>
        {{ reset_dialog.title }}
      </template>
      <template #default>
        <p
          v-show="reset_form.step === 1"
          class="signin__dialog-descr"
        >
          На номер <span>{{ reset_form.fields.phone.value }}</span> был
          отправлен СМС код для восстановления пароля
        </p>

        <v-input
          v-show="reset_form.step === 0"
          v-model="reset_form.fields.phone.value"
          :attrs="reset_form.fields.phone.attrs"
          class="signin__dialog-inp"
          :error-bag="reset_form.fields.phone.errorBag"
        />
        <v-checkbox
          v-show="reset_form.step === 0"
          ref="recaptchaResetRef"
          v-model="reset_recaptcha_token.value"
          value="Принимаю"
          class="acceptDocs form__checkbox"
          @change="recaptcha('recaptchaResetRef', reset_recaptcha_token)"
        >
          Я не робот
        </v-checkbox>
        <v-input
          v-show="reset_form.step === 1"
          v-model="reset_form.fields.sms_code.value"
          :attrs="reset_form.fields.sms_code.attrs"
          class="signin__dialog-inp"
          :error-bag="reset_form.fields.sms_code.errorBag"
        />
        <v-input
          v-show="reset_form.step === 2"
          v-model="reset_form.fields.password.value"
          :attrs="reset_form.fields.password.attrs"
          class="signin__dialog-inp"
          :error-bag="reset_form.fields.password.errorBag"
        />
        <v-btn
          class="signin__dialog-btn"
          @click="reset"
        >
          Отправить код подтверждения
        </v-btn>
      </template>
    </v-popup>
    <v-popup
      class="feedback__dialog"
      :visible="feedback_dialog.visible"
      @close-popup="closeDialog('feedback_dialog')"
    >
      <template #title>
        {{ feedback_dialog.title }}
      </template>
      <template #default>
        <p class="feedback__dialog-descr">
          Оставьте контакты для обратной связи
        </p>
        <v-input
          v-model="feedback_form.fields.fio.value"
          :attrs="feedback_form.fields.fio.attrs"
          class="feedback__dialog-inp"
          :error-bag="feedback_form.fields.fio.errorBag"
        />
        <v-input
          v-model="feedback_form.fields.phone.value"
          :attrs="feedback_form.fields.phone.attrs"
          class="feedback__dialog-inp"
          :error-bag="feedback_form.fields.phone.errorBag"
        />
        <v-input
          v-model="feedback_form.fields.msg.value"
          :attrs="feedback_form.fields.msg.attrs"
          class="feedback__dialog-inp"
        />
        <v-btn
          class="feedback__dialog-btn"
          @click="sendFeedback"
        >
          Отправить
        </v-btn>
      </template>
    </v-popup>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import gsap from 'gsap'
import md5 from 'md5'

import { required, validateMinLength } from '@/utils/validates'
import { toServerPhone } from '@/utils/converters'

import VBtn from '@/components/UI/v-btn.vue'
import VPopup from '@/components/UI/v-popup.vue'
import VInput from '@/components/UI/v-input.vue'
import VCheckbox from '@/components/UI/v-checkbox.vue'
import AuthForm from '@/components/AuthForm.vue'
import VIcon from '@/components/UI/v-icon.vue'

export default {
  name: 'VHeader',
  components: { VIcon, AuthForm, VCheckbox, VInput, VPopup, VBtn },
  data() {
    return {
      menuToggle: false,
      signin_dialog: {
        visible: false,
        title: 'Вход в личный кабинет',
      },
      reset_dialog: {
        visible: false,
        title: 'Восстановление пароля',
      },
      feedback_dialog: {
        visible: false,
        title: 'Обратная связь',
      },
      signin_form: {
        step: 0,
        fields: {
          password: {
            value: '',
            attrs: {
              id: 'password',
              placeholder: 'Введите пароль',
              type: 'password',
              label: 'Пароль',
              maska: {
                mask: 'HHHHHHHHHHHHHHHHHHHHHHH',
                tokens: { H: { pattern: /[A-Z-a-z0-9 ]/ } },
              },
            },
            validate: {
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
          phone: {
            value: '',
            attrs: {
              id: 'login_phone',
              label: 'Ваш номер телефона',
              placeholder: 'Введите номер',
              maska: {
                mask: '+7 (###) ###-##-##',
                pattern: /\D/g,
              },
            },
            validate: {
              validateMinLength: {
                fn: validateMinLength,
                args: [18, 11],
              },
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
        },
      },
      reset_form: {
        step: 0,
        fields: {
          password: {
            value: '',
            attrs: {
              id: 'reset_password',
              placeholder: 'Введите новый пароль',
              type: 'password',
              label: 'Пароль',
              maska: {
                mask: 'HHHHHHHHHHHHHHHHHHHHHHH',
                tokens: { H: { pattern: /[A-Z-a-z0-9 ]/ } },
              },
            },
            validate: {
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
          phone: {
            value: '',
            attrs: {
              id: 'reset_phone',
              label: 'Ваш номер телефона',
              placeholder: 'Введите номер',
              maska: {
                mask: '+7 (###) ###-##-##',
                pattern: /\D/g,
              },
            },
            validate: {
              validateMinLength: {
                fn: validateMinLength,
                args: [18, 11],
              },
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
          sms_code: {
            value: '',
            attrs: {
              id: 'reset_sms_code',
              placeholder: 'Введите код подтверждения',
              label: 'Код подтверждения',
              maska: {
                mask: '####',
              },
            },
            validate: {
              validateMinLength: {
                fn: validateMinLength,
                args: [4, 4],
              },
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
        },
      },
      feedback_form: {
        step: 0,
        fields: {
          fio: {
            value: '',
            attrs: {
              id: 'fio_feedback_header',
              placeholder: 'Введите ФИО',
              label: 'ФИО',
              maska: {
                mask: 'HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH',
                tokens: { H: { pattern: /[А-Яа-я ]/ } },
              },
            },
            validate: {
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
          msg: {
            value: '',
            attrs: {
              id: 'message_feedback',
              placeholder: 'Введите текст обращения',
              label: 'Текст обращения',
              maska: {
                mask: 'HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH',
                tokens: { H: { pattern: /[А-Яа-я-/,0-9 ]/ } },
              },
            },
            errorBag: [],
            isDirty: false,
          },
          phone: {
            value: '',
            attrs: {
              id: 'phone_feedback_header',
              label: 'Ваш номер телефона',
              placeholder: 'Введите номер',
              maska: {
                mask: '+7 (###) ###-##-##',
                pattern: /\D/g,
              },
            },
            validate: {
              validateMinLength: {
                fn: validateMinLength,
                args: [18, 11],
              },
              required: {
                fn: required,
                args: [],
              },
            },
            errorBag: [],
            isDirty: false,
          },
        },
      },
      need_recaptcha: false,
      login_recaptcha_token: {
        value: [],
        token: '',
        validate: {
          required: {
            fn: required,
            args: [],
          },
        },
        errorBag: [],
        isDirty: false,
      },
      reset_recaptcha_token: {
        value: [],
        token: '',
        validate: {
          required: {
            fn: required,
            args: [],
          },
        },
        errorBag: [],
        isDirty: false,
      },
    }
  },
  computed: {
    ...mapGetters(['TOKEN', 'USER']),
    phone_valid() {
      const phone = this.signin_form.fields.phone

      return !!(!phone.errorBag.length && phone.isDirty)
    },
    password_valid() {
      const password = this.signin_form.fields.password

      return !!(!password.errorBag.length && password.isDirty)
    },
    reset_phone_valid() {
      const phone = this.reset_form.fields.phone

      return !!(!phone.errorBag.length && phone.isDirty)
    },
    reset_password_valid() {
      const password = this.reset_form.fields.password

      return !!(!password.errorBag.length && password.isDirty)
    },
    reset_recaptcha_valid() {
      const recaptcha_field = this.reset_recaptcha_token

      return Boolean(recaptcha_field.token?.length)
    },
    authorized() {
      return this.TOKEN?.length > 10 && this.$route.meta.auth
    },
    feeback_valid() {
      return !Object.values(this.feedback_form.fields).filter(
        (item) => item?.errorBag?.length || (!item?.isDirty && item?.validate)
      ).length
    },
    userAlias() {
      return this.USER ? this.USER.first_name?.charAt(0) : ''
    },
  },
  watch: {
    menuToggle(value) {
      if (value) this.menuAnimation.play()
      else this.menuAnimation.reverse()
    },
  },
  mounted() {
    this.initMenu()
  },
  methods: {
    ...mapActions([
      'TEST',
      'LOGIN',
      'SEND_FEEDBACK',
      'addNotification',
      'RESET_PASSWORD',
    ]),
    authButtonHandler() {
      if (!this.authorized) {
        this.signin_dialog.visible = true
      }
    },
    showResetDialog() {
      if (!this.authorized) {
        this.closeDialog('signin_dialog')
        this.reset_dialog.visible = true
      }
    },
    async submitAuthForm({ data, status }) {
      if (status === 200) {
        this.$router.push({
          path: '/loan',
          query: { 'user_id': data.data.client.id },
        })
      }
      if (status === 422) {
        await this.$store.commit('SET_TOKEN', data.data.token)
        this.closeDialog('signin_dialog')
        this.$router.push({
          name: 'signup',
          params: {
            phone: `+${data.data.client.phone}`
          },
          query: {
            'user_id': data.data.client.id,
          }
        })
      }
    },
    toggleMenu() {
      this.menuToggle = !this.menuToggle
    },
    initMenu() {
      const menu = this.$refs?.menu
      const wrapper = this.$refs?.wrapper
      const line = menu?.children[0]
      const line2 = menu?.children[1]
      const line3 = menu?.children[2]

      this.menuAnimation = gsap.timeline({ paused: true })
      this.menuAnimation?.to(line2, 0.2, { opacity: 0 })
      this.menuAnimation?.to(line, 0.4, { top: '50%', rotate: '45deg' })
      this.menuAnimation?.to(
        line3,
        0.4,
        { top: '50%', rotate: '-45deg' },
        '-=0.4'
      )
      this.menuAnimation?.to(wrapper, { x: 0 }, '-=0.4')
    },
    async recaptcha(ref, data) {
      grecaptcha.ready(() => {
        grecaptcha
          .execute('6Ldy0vcmAAAAAEi0lP7_ifBorQRLeecPFTtrkaUE', {
            action: 'submit',
          })
          .then((token) => {
            this.$refs[ref].$el.style.pointerEvents = 'none'

            if (token !== '' && token !== undefined && token !== null) {
              data.token = token
              localStorage.setItem('reset_recaptcha_token', token)
            } else {
              data.value = []
            }
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    openDialog(dialog) {
      if (dialog === 'signin_dialog' && this.authorized) {
        this.$router.push('/')
        this.$store.state.dashboard.token = ''
        this.$store.state.dashboard.active_orders = []
      } else this[dialog].visible = true
    },
    closeDialog(dialog) {
      this[dialog].visible = false
    },
    reset() {
      const step = this.reset_form.step

      // this.reset_form.step++;
      if (step === 0) {
        this.reset_form.fields.phone.validate.required.fn(
          this.reset_form.fields.phone
        )
        this.reset_form.fields.phone.validate.validateMinLength.fn(
          this.reset_form.fields.phone,
          18,
          11
        )
      }

      if (step === 1) {
      }

      if (step === 2) {
        this.reset_form.fields.password.validate.required.fn(
          this.reset_form.fields.password
        )
      }

      this.$nextTick(async () => {
        const payload = {}

        if (
          this.reset_phone_valid &&
          // this.reset_recaptcha_valid &&
          step === 0
        ) {
          const payload_phone = toServerPhone(
            this.reset_form.fields.phone.value
          )

          payload.phone = payload_phone
          payload.hash = md5(
            `${payload_phone}_Pa$$word!${payload_phone}Acado_Nfkrldirjdh_${payload_phone}`
          )

          this.RESET_PASSWORD({ step, payload })
            .then((res) => {
              if (res?.data?.code === 200) {
                this.reset_form.step++
                localStorage.setItem('user_id', res?.data?.data?.client_id)
              }
            })
            .catch((err) => {
              if (err?.response?.status === 500) {
                this.addNotification({ message: 'Неизвестная ошибка' })
              } else {
                this.addNotification({ message: err?.response?.data?.message })
              }
            })
        }
        if (this.reset_phone_valid && step === 1) {
          payload.code = this.reset_form.fields.sms_code.value
          payload.user_id = localStorage.getItem('user_id')
          this.RESET_PASSWORD({ step, payload })
            .then((res) => {
              if (res?.data?.data?.is_confirmed) {
                this.reset_form.step++
              }
              if (!res?.data?.data?.is_confirmed) {
                this.addNotification({ message: 'Проверьте код' })
              }
            })
            .catch((err) => {
              if (err?.response?.status === 500) {
                this.addNotification({ message: 'Неизвестная ошибка' })
              } else {
                this.addNotification({ message: err?.response?.data?.message })
              }
              console.log(err)
            })
        }
        if (this.reset_phone_valid && step === 2) {
          payload.password = this.reset_form.fields.password.value
          payload.user_id = localStorage.getItem('user_id')
          payload.code = this.reset_form.fields.sms_code.value
          this.RESET_PASSWORD({ step, payload })
            .then((res) => {
              console.log(res)
              if (res?.data?.code === 200) {
                this.addNotification({ message: 'Пароль успешно изменён', type: 'success' })
                this.signin_form.fields.phone.value =
                  this.reset_form.fields.phone.value
                this.closeDialog('reset_dialog')
                this.openDialog('signin_dialog')
              }
            })
            .catch((err) => {
              console.log(err)
              if (err?.response?.status === 500) {
                this.addNotification({ message: 'Неизвестная ошибка' })
              } else if (
                err?.response?.status === 422 &&
                err?.response?.data?.message.includes('8 characters')
              ) {
                this.addNotification({
                  message: 'Должен содержать минимум 8 символов',
                })
              } else if (err?.response?.status === 422) {
                this.addNotification({
                  message: err?.response?.data?.message,
                })
              } else {
                this.addNotification({ message: err?.response?.data?.message })
              }
            })
        }
      })
    },
    async sendFeedback() {
      Object.keys(this.feedback_form.fields).map((item) => {
        if (this.feedback_form.fields[item]?.validate) {
          Object.values(this.feedback_form.fields[item].validate).map((el) => {
            el.fn(this.feedback_form.fields[item], ...el.args)
          })
        }
      })

      if (this.feeback_valid) {
        const payload = {
          phone: toServerPhone(this.feedback_form.fields.phone.value),
          fio: this.feedback_form.fields.fio.value,
          message: this.feedback_form.fields.msg.value,
        }

        await this.SEND_FEEDBACK(payload).then((res) => {
          const status = res?.data?.code || res?.status

          if (status === 200) {
            this.closeDialog('feedback_dialog')
            this.addNotification({
              message: 'Форма успешно отправлена', type: 'success'
            })
          } else {
            this.addNotification({
              message: 'Что-то пошло не так',
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-header {
  background-color: $white;

  /* .v-header__user-badge */
  &__user-badge {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $accent;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
  }

  /* .v-header__burger */
  &__burger {
    min-height: 12px;
    min-width: 24px;
    border-radius: 50%;
    display: none;
    position: relative;
    &::after {
      content: "";
      background-color: #f4f8f9;
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: 2;
      top: 50%;
      left: 50%;
    }
  }

  &__actions-link {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-right: 20px;
    :first-child {
      font-size: 14px;
      font-weight: 400;
    }
    :last-child {
      font-size: 16px;
      font-weight: 400;
    }
  }

  /* .v-header__burger-line */
  &__burger-line {
    z-index: 3;
    width: 100%;
    height: 2px;
    position: absolute;
    &:nth-child(1) {
      top: 1px;
      background-color: $accent;
      // transform-origin: center;
    }
    &:nth-child(2) {
      top: 60%;
      width: 60%;
      transform: translateY(-50%);
      background-color: $accent;
    }
    &:nth-child(3) {
      top: 100%;
      background-color: $accent;
      // transform-origin: center;
    }
  }

  /* .v-header__container */
  &__container {
    max-width: 1520px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    margin-top: 20px;
  }

  &__nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    background: $silver;
    border-radius: 50px;
  }

  /* .v-header__logo */
  &__logo {
    position: relative;
    z-index: 2;
    font-size: 36px;
    font-family: "Inter", sans-serif;
    font-weight: 900;
    white-space: nowrap;
    color: $accent;
    cursor: pointer;
  }

  /* .v-header__links */
  &__links {
    display: flex;
    gap: 40px;
    align-items: center;
  }

  /* .v-header__link */
  &__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    cursor: pointer;
    text-transform: uppercase;
    &:last-child {
      margin-right: 0;
    }
  }

  /* .v-header__actions */
  &__actions {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;

    /* .v-header__actions-link */
    &-links {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    /* .v-header__actions-link */
    &-link {
      text-align: right;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 400;
      &_mail {
        font-weight: 600;
      }
    }
  }

  /* .v-header__right */
  &__right {
    display: flex;
    gap: 20px;
  }

  /* .v-header__contacts */
  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 23px;
  }

  /* .v-header__contacts-title */
  &__contacts-title {
    color: $accent;
    display: none;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  /* .v-header__contact */
  &__contact {
    font-weight: 400;
    font-size: 14px;
  }

  /* .v-header__btn */
  &__btn {
    margin-right: 12px;
  }
}

@media (max-width: 1400px) and (min-width: 1201px) {
  .v-header {
    &__logo {
      width: 100px;
    }
    &__links {
      gap: 15px;
    }
    &__right {
      gap: 10px;
    }
  }
}
@media (min-width: 1201px) {
  .v-header {
    &__auth_mobile,
    &__menu,
    &__lk {
      display: none;
    }
  }
}
@media (max-width: 1200px) {
  .v-header {
    /* .v-header__container */
    &__menu {
      padding: 150px 50px 150px 30px;
      padding-top: 150px;
      position: fixed;
      display: flex;
      flex-direction: column;
      gap: 20px;
      z-index: 5;
      top: 0;
      left: 0;
      height: 100vh;
      background: $silver;
      transition: transform 0.5s ease-in-out;
      &:not(&_active) {
        transform: translateX(-100%);
      }
    }

    /* .v-header__burger */
    &__burger {
      display: flex;
      margin-right: 24px;
      position: fixed;
      left: 20px;
      z-index: 6;
    }

    &__logo {
      margin: 0 auto;
    }

    /* .v-header__links */
    /* .v-header__RIGHT */
    &__links,
    &__right {
      display: none;
    }

    /* .v-header__link */
    &__link {
      margin-right: 0;
      font-size: inherit;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    /* .v-header__actions */
    &__actions {
      flex-direction: column;
      flex-grow: initial;
      align-items: flex-start;
    }

    &__auth_mobile {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }

    /* .v-header__contacts */
    &__contacts {
      margin-right: 0;
      margin-bottom: 20px;
    }

    /* .v-header__contacts-title */
    &__contacts-title {
      display: initial;
    }

    /* .v-header__contact */
    &__contact {
      font-size: inherit;
    }
  }
}
@media (max-width: 575px) {
  .v-header {
    &__container {
      margin-top: 0;
    }
    &__nav {
      border-radius: 0;
      background: none;
    }
    /* .v-header__logo */
    &__logo {
      margin: 0;
      margin-left: 50px;
        width: 110px;
    }
  }
}

.feedback__dialog-descr {
  font-size: 13px;
  margin-bottom: 12px;
}
.feedback__dialog-inp {
  margin-bottom: 12px;
}
</style>

<style lang="scss">
.condition {
  /* .condition__dialog-subtitle */
  &__dialog-subtitle {
    font-size: 13px;
    line-height: 130% !important;
    color: $third-grey;
    margin-bottom: 15px !important;
  }

  /* .condition__dialog-btn */
  &__dialog-btn {
    margin-top: 10px;
    width: 100%;
  }
}
</style>
