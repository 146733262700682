<template>
  <div
    v-if="cItems && cItems.length && cItems.length > 0"
    class="header-nav"
  >
    <div class="header-nav__link-wrapper d-flex d-sm-none">
      <LogInButtons />
    </div>
    <div
      v-for="(item, wIndex) in cItems"
      :key="'header-nav__link-wrapper-' + wIndex"
      class="header-nav__link-wrapper"
    >
      <span
        v-if="item?.params?.disabled"
        class="header-nav__link"
        :class="{'js-toggle-dropdown': item.items && item.items.length && item.items.length > 0}"
      >
        {{ item.name }}
      </span>
      <router-link
        v-else
        :to="item.link"
        class="header-nav__link"
      >
        {{ item.name }}
      </router-link>

      <button
        v-if="item.items && item.items.length && item.items.length > 0"
        class="header-nav__link-button js-toggle-dropdown"
      >
        <svg
          width="20"
          height="20"
        >
          <use xlink:href="#chevron-down" />
        </svg>
      </button>

      <div
        v-if="item.items && item.items.length && item.items.length > 0"
        class="header-nav__dropdown"
      >
        <div class="header-nav__dropdown-list">
          <router-link
            v-for="(subitem, lIndex) in item.items"
            :key="'header-nav__dropdown-link-'+lIndex"
            :to="subitem.link"
            class="header-nav__dropdown-link"
          >
            {{ subitem.name }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, onServerPrefetch, ref } from 'vue'
import { dataProvider } from '@/utils/dataProvider'
import LogInButtons from '@/components1/common/LogInButtons.vue'

const items = ref([])
const loadMenu = async () => {
  items.value = await dataProvider.getTopMenu() || []
}

onMounted(loadMenu)
onServerPrefetch(loadMenu)

const cItems = computed(() => {
  return items.value.map((item) => {
    return item?.attributes || undefined
  }).filter((item) => {
    return !!item
  })
})
</script>
