<template>
  <div
    class="cookies"
    :style="{display: cookiesDisplay}"
  >
    <div class="cookies__in">
      <div class="cookies__text">
        ООО МКК «К2КАПИТАЛ» использует файлы «cookie» с целью персонализации сервисов и повышения удобства пользования веб-сайтом. Если вы не хотите использовать файлы «cookie», пожалуйста, измените настройки вашего браузера.
      </div>
      <button
        class="button button-thirdary js-set-cookies"
        @click.prevent="closeCookies"
      >
        Понятно
      </button>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'

const isDisplay = ref(false)

onMounted(() => {
  const acceptCookies = localStorage.getItem('acceptCookies')

  setTimeout(() => {
    if(!acceptCookies){
      isDisplay.value = true
    }
  }, 3000)
})

const cookiesDisplay = computed(() => {
  return isDisplay.value ? '': 'none'
})

const closeCookies = () => {
  isDisplay.value = false
  localStorage.setItem('acceptCookies', 'Y')
}
</script>
<style lang="scss" scoped>

</style>
