<template>
  <div
    class="notifications-container"
  >
    <transition-group
      name="right"
    >
      <div
        v-for="(notification, idx) in notifications"
        :key="idx"
        ref="notification"
        class="notification"
        :class="{'danger-notification': !notification.type||notification === 'error', 'success-notification': notification.type === 'success'}"
      >
        {{ notification.message }}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="remove-notify"
          @click="removeNotification(idx)"
        >
          <g opacity="0.6">
            <path
              d="M6 18L18 6M6 6L18 18"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'VNotification',
  computed: {
    ...mapGetters(['getNotifications']),
    notifications () {
      return this.getNotifications
    }
  },
  mounted () {
    this.$store.subscribe(this.delayRemoveNotification)
  },
  methods: {
    ...mapActions(['removeNotification']),
    delayRemoveNotification () {
      let nKeys = Object.keys(this.notifications)

      setTimeout(() => {
        this.removeNotification(nKeys[nKeys.length -1])
      }, 5000)
    }
  }
}
</script>

<style scoped lang="scss">
.notifications-container {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 99999999999999999;
  border-radius: 10px;
  pointer-events: none;

  @media (min-width: 768px) {
    top: 50px;
    right: 32px;
  }
}

.notification {
  padding: 15px 50px 15px 16px;
  line-height: 100%;
  margin-bottom: 10px;
  position: relative;
  pointer-events: auto;
}
.danger-notification{
  background-color: #9A273B;
  color: $white;
  box-shadow: 8px 16px 60px rgba(154, 39, 59, 0.25);
}
.success-notification{
  box-shadow: 8px 16px 60px rgba(37, 190, 172, 0.25);
}
.remove-notify{
  cursor: pointer;
  font-size: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.right-enter-active,
.right-leave-active {
  transition: opacity 0.5s ease, left 0.5s ease;
  left: 0;
}

.right-enter-from,
.right-leave-to {
  opacity: 0;
}

.right-enter-from{
  left: -100%;
}
.right-leave-to {
  left: 100%;
}

</style>
