<template>
  <Preloader v-if="loading" />
  <div class="layout">
    <Header />
    <main class="layout__in">
      <router-view />
    </main>
    <Footer />
    <PopupItems />
  </div>
  <Svg />
</template>

<script setup>
import { computed, onMounted } from 'vue'

import Preloader from '@/components1/Preloader.vue'
import Header from '@/components1/parts/Header.vue'
import Footer from '@/components1/parts/Footer.vue'
import Svg from '@/components1/Svg.vue'
import PopupItems from '@/components1/PopupItems.vue'
import initHeader from '@/utils/initHeader'

const loading = computed(() => {
  return false
})

onMounted(() => initHeader())
</script>
