<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-xl-7">
          <div class="footer-left">
            <div class="footer-top spacing-1">
              <button
                class="footer-link link"
                data-fancybox
                data-src="#popup-location"
              >
                <svg>
                  <use xlink:href="#location" />
                </svg>
                <span>{{ userCity }}</span>
              </button>
              <a
                href="tel:88005504665"
                class="footer-link link"
              >
                <svg>
                  <use xlink:href="#tel-1" />
                </svg>
                <span>8 800 550-46-65</span>
              </a>
              <a
                href="mailto:Info@smartzaym.ru"
                class="footer-link link"
              >
                <svg>
                  <use xlink:href="#email" />
                </svg>
                <span>Info@smartzaym.ru</span>
              </a>
            </div>
            <div class="footer-nav spacing-1">
              <router-link
                class="footer-nav__link"
                to="/clients/payment"
              >
                Внесение платежей
              </router-link>
              <router-link
                class="footer-nav__link"
                to="/clients/overdue"
              >
                Просрочка платежей
              </router-link>
              <router-link
                class="footer-nav__link"
                to="/clients/faq"
              >
                Вопрос-ответ
              </router-link>
              <router-link
                class="footer-nav__link"
                to="/about"
              >
                О компании
              </router-link>
              <router-link
                class="footer-nav__link"
                to="/clients/documents"
              >
                Документы
              </router-link>
              <router-link
                class="footer-nav__link"
                to="/media/blog"
              >
                Пресс-центр
              </router-link>
              <router-link
                class="footer-nav__link"
                to="/vacancy"
              >
                Вакансии
              </router-link>
              <router-link
                class="footer-nav__link"
                to="/media/press"
              >
                Для СМИ
              </router-link>
              <router-link
                class="footer-nav__link"
                to="/contacts"
              >
                Контакты
              </router-link>
            </div>
            <div class="lh12 text5 spacing-35">
              Пример расчета: Пример расчёта полной стоимости займа: при займе под 2.7% в месяц, полная стоимость займа составит 2.7 × 12 (месяцев) = 32.4% в год. При займе 100 000,00 руб. на 1 год, ежемесячный платеж составит 9 867,14 руб., сумма процентов через 1 месяц составит 2 700,00 руб., через 12 месяцев 18 405,74 руб. ПСК: от 32.4% до 105.673% годовых. Комиссия за оформление займа отсутствует. Минимальный период пользования займом составляет 30 дней, максимальный 60 месяцев. Пеня при просрочке составляет 20% годовых от суммы просроченной задолженности за каждый календарный день.
            </div>
            <div class="lh12 text5">
              «К2КАПИТАЛ» 2024. Все права защищены.
            </div>
          </div>
        </div>
        <div class="col-xl-5">
          <div class="footer-right">
            <div class="text5 color-dark-400 lh12">
              <div class="spacing-4">
                Общество с ограниченной ответственностью Микрокредитная компания «К2КАПИТАЛ» (ООО МКК «К2КАПИТАЛ»). ОГРН <span class="color-dark-400">1237800132580</span> ИНН <span class="color-dark-400">7814830469</span>. <span class="color-dark-400">191167</span>, г. Санкт-Петербург, вн.тер.г. муниципальный округ Лиговка-Ямская, ул Кременчугская, д. 19, к. 1, стр. 1, помещ. 68н
              </div>
              <div class="spacing-4">
                ООО МКК «К2КАПИТАЛ» с 15.02.2024 является членом <a
                  href="https://npmir.ru/"
                  target="_blank"
                  rel="nofollow"
                  class="link-underline"
                >СРО «МиР»</a> (107078, г. Москва, Орликов переулок, д.5, стр.1, этаж 2, пом.11).
              </div>
              <div class="spacing-4">
                Регистрационный номер в государственном <a
                  href="https://cbr.ru/microfinance/registry/"
                  target="_blank"
                  rel="nofollow"
                  class="link-underline"
                >реестре МФО</a>&nbsp;
                <router-link
                  to="/clients/documents"
                  class="link-underline"
                >
                  2403140010017 от 17.01.2024.
                </router-link>&nbsp;
                <a
                  href="https://www.cbr.ru/"
                  target="_blank"
                  rel="nofollow"
                  class="link-underline"
                >Сайт Банка России.</a>
              </div>

              <div class="spacing-4">
                Потребители финансовых услуг имеют право направить обращение финансовому уполномоченному. Место нахождения/Почтовый адрес: 119017, г. Москва, ул. Старомонетный пер., д. 3, тел.: 8 (800) 200-00-10, сайт: <a
                  href="https://finombudsman.ru"
                  target="_blank"
                  rel="nofollow"
                  class="link-underline"
                >https://finombudsman.ru</a>
              </div>

              <div class="d-flex flex-column align-items-start gap-5">
                <router-link
                  class="footer-link link-underline"
                  to="/privacy"
                >
                  Политика конфиденциальности
                </router-link>

                <router-link
                  class="footer-link link-underline"
                  to="/sitemap"
                >
                  Карта сайта
                </router-link>

                <a
                  class="footer-link link-underline"
                  href="https://www.cbr.ru/reception/"
                  target="_blank"
                  rel="nofollow"
                >Приемная Банка России</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
//todo: механизм с городами переделать!
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const defaultCity = 'Москва и Московская область'
const userCity = computed(() => {
  return store.state.userCity || defaultCity
})
</script>

<style scoped lang="scss">

</style>
